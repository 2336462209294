<div class="lg:mt-8 pt-6 lg:pt-8 max-w-screen-xl mx-auto bg-blue-100">
  <div class="lg:mt-8 lg:p-8">
    <div class="text-center mb-4">
      <h3 class="lg:block text-lg lg:text-xl">Your Dashboard</h3>
      <hr />
    </div>

    <div class="flex flex-wrap mx-3 lg:ml-6">
      <div class="w-full mt-5">
        <app-tabs
          [activeTabClass]="'bg-gray-alster text-white'"
          [contentClasses]="
            'bg-white  border-2  border-gray-alster rounded-[20px] bg-blue-100'
          "
          [inactiveTabClass]="'bg-white'"
          [navClasses]="'flex  w-full px-6'"
          [tabClasses]="
            'font-semibold  rounded-[20px] lg:rounded-none lg:rounded-t-[20px] text-xs   px-4 py-2 text-gray-700 border-2 lg:border-b-0 border-gray-alster hover:border-0 focus:outline-none cursor-pointer lg:text-md text-center '
          "
          [tabRowClasses]="' rounded-t-lg  flex items-center'"
        >
          <app-tab title="My Events">
            <div class="event-list-border p-4 rounded-[20px]">
              <div>
                @if (nextEvent()) {
                  <h4 class="mb-4">Next Event</h4>
                }
                @if (!nextEvent()) {
                  <h4>You are not registered for any upcoming event.</h4>
                }
              </div>
              <app-customer-event-grid
                [events]="nextEvent"
                [listType]="'nextEvent'"
                class="w-full"
              ></app-customer-event-grid>
            </div>
            @if (upcomingEvents().length > 0) {
              <div class="w-full">
                <hr />
              </div>
            }

            @if (upcomingEvents().length > 0) {
              <div class="event-list-border p-4 rounded-[20px]">
                <div>
                  <h4 class="mb-4">More Upcoming Events</h4>
                </div>
                <app-customer-event-grid
                  class="w-full"
                  [listType]="'upcomingEvents'"
                  [events]="upcomingEvents"
                ></app-customer-event-grid>
              </div>
            }
            @if (pastEvents.length > 0) {
              <div class="w-full">
                <hr />
              </div>
            }
          </app-tab>
          <app-tab title="All Upcoming Events">
            <div class="bg-blue-100 min-h-[50px] p-4 rounded-[20px]">
              <div>
                <app-event-grid-quickmode></app-event-grid-quickmode>
              </div>
            </div>
          </app-tab>
          <app-tab title="My Event Archive">
            <div class="event-list-border p-4">
              <div>
                <app-customer-event-grid
                  [events]="pastEvents"
                  [listType]="'pastEvents'"
                  class="w-full"
                ></app-customer-event-grid>
              </div>
            </div>
          </app-tab>

          <app-tab title="Company Research">
            <div class="event-list-border p-4 rounded-[20px]">
              <div>
                <app-company-overview></app-company-overview>
              </div>
            </div>
          </app-tab>
          <app-tab title="My Newsletters">
            <div class="event-list-border p-4 rounded-[20px]">
              <div>
                <app-investor-newsletter></app-investor-newsletter>
              </div>
            </div>
          </app-tab>
          <app-tab title="My Profile">
            <div class="event-list-border p-4 rounded-[20px]">
              <app-investor-profile-view
                (profileUpdated)="initData()"
                [userInvestorProfile]="userInvestorProfile"
              ></app-investor-profile-view>
            </div>
          </app-tab>
        </app-tabs>
      </div>
    </div>
  </div>
</div>
