import { JsonPipe, NgStyle } from "@angular/common";
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  Output,
  Signal,
  signal,
  WritableSignal,
} from "@angular/core";

import { ExcelService } from "~features/company/excel.service";
import { Tables } from "~features/tables/tables.util";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";

interface figureData {
  title: string;
  value: string | any[];
  valueModifier: string;
  date?: any[];
}

@Component({
  selector: "app-figure",
  templateUrl: "./figure.component.html",
  styleUrls: ["./figure.component.scss"],
  standalone: true,
  imports: [NgStyle, JsonPipe],
})
export class FigureComponent {
  @Input() companyData: Signal<any>;
  @Input() baseyear: Signal<number> = signal(Number(new Date().getFullYear()));
  @Input() tile: WritableSignal<any> = signal(null);
  @Input() decimals = 0;
  @Input() lang = "eng";
  initialIndexSetOnce = false;
  @Output() tileEvent: EventEmitter<any> = new EventEmitter();
  excelService = inject(ExcelService);
  excelMapping = this.excelService.getExcelMapping();

  data: WritableSignal<figureData> = signal({
    title: "",
    value: [""],
    valueModifier: "",
    date: [""],
  });

  index: WritableSignal<number> = signal(0);
  bgColor = signal("transparent");
  color = signal("black");

  constructor(private tableUtils: Tables) {
    effect(() => {
      const mapping = this.excelMapping();
      const companyNumbers = this.companyData();
      this.prepareData(mapping, companyNumbers);
    }, enableSignalWritesInEffectContext);
  }

  prepareData(mapping, companyNumbers) {
    if (mapping && companyNumbers && companyNumbers) {
      for (const key of this.tile().content) {
        //  console.log("WORKONG ON KEY ", key);
        if (mapping[key]) {
          //  console.log("FOUND  ON KEY ", mapping[key]);
          const mappingEntry = mapping[key];
          let entry;
          if (mappingEntry.type === "VALUE") {
            //    console.log("IS VALUE", key);
            entry = {
              title: mappingEntry[this.lang],
              value: this.tableUtils.getValue(
                companyNumbers,
                mappingEntry,
                this.lang,
                this.decimals
              )[0],
            };
          } else if (mappingEntry.type === "TIMESERIES") {
            //  console.log("IS TIME SERIES", key);
            entry = {
              title: mappingEntry[this.lang],
              value: this.tableUtils.getTimeseries(
                key,
                companyNumbers,
                mappingEntry,
                2,
                3,
                "value",
                this.lang,
                this.decimals
              ),
              date: this.tableUtils.getTimeseries(
                key,
                companyNumbers,
                mappingEntry,
                2,
                3,
                "date",
                this.lang,
                this.decimals
              ),
            };
          } else {
            //     console.log("NO MAPPING ENTRY TYPE");
          }

          if (entry) {
            if (!mappingEntry.unit) {
              entry.valueModifier = companyNumbers?.base?.curr + "m";
            }

            this.data.set(entry);
            console.log(entry);
            this.setInitialIndexOne(this.initialIndexSetOnce);
          }
          //      console.log("DATA", this.data());
          this.updateColor();
        }
      }
    }
  }

  navigate(step: number) {
    const length = this.data()["value"].length;
    this.index.set((this.index() + step + length) % length);
    this.updateColor();
  }

  setInitialIndexOne(alreadySet) {
    if (!alreadySet) {
      const currentYear = new Date().getFullYear();
      const targetYear = (currentYear - 1).toString();
      const dates = this.data().date;

      if (dates && dates.length > 0) {
        const index = dates.findIndex((date) => date.includes(targetYear));
        if (index !== -1) {
          this.index.set(index);
        } else {
          // If the target year is not found, set to the most recent year available
          this.index.set(dates.length - 1);
        }
      }
    }
    this.initialIndexSetOnce = true;
    this.updateColor(); // Update color based on the new index
  }

  updateColor() {
    const key = this.tile().content[0];
    if (key === "roce") {
      const matches = String(this.data()["value"][this.index()]).match(
        /-?\d+(.\d+)?/g
      );
      if (matches) {
        const value: number = parseInt(matches[0]);
        if (value < -5) {
          this.bgColor.set("#fda4af");
          this.color.set("#f43f5e");
        } else if (value < 0) {
          this.bgColor.set("#FEF2F2");
          this.color.set("#f43f5e");
        } else if (value < 8) {
          this.bgColor.set("#FFFBEB");
          this.color.set("#EAB308");
        } else if (value < 12) {
          this.bgColor.set("#ECFDF5");
          this.color.set("#047857");
        } else if (value >= 12) {
          this.bgColor.set("#86efac");
          this.color.set("#047857");
        }
      }
      this.tileEvent.emit({
        type: "color-change",
        change: { backgroundColor: this.bgColor(), color: this.color() },
      });
    }
  }
}
