import { DialogModule } from "@angular/cdk/dialog";
import { DecimalPipe, JsonPipe, NgClass } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  Signal,
  signal,
  ViewChildren,
  WritableSignal,
} from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import {
  ColorType,
  createChart,
  IChartApi,
  ISeriesApi,
  UTCTimestamp,
} from "lightweight-charts";
import { IEditorContent } from "~global-interfaces/IEditorContent";

import { SmoothscrollDirective } from "~directives/smoothscroll.directive";
import { BookAMeetingComponent } from "~features/book-a-meeting/book-a-meeting.component";
import { ShareholdersBarchartComponent } from "~features/charts/shareholders-barchart/shareholders-barchart.component";
import { CompanyInfoService } from "~features/company/company-info.service";
import { CompanyRatingService } from "~features/company/company-rating.service";
import { CompanyResearchService } from "~features/company/company-research.service";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { CompanydataService } from "~features/company/companydata.service";
import { CompanyinfoSliderComponent } from "~features/company/companyinfo-slider/companyinfo-slider.component";
import { CompanylistService } from "~features/company/companylist.service";
import { ExcelService } from "~features/company/excel.service";
import { InitiationNoteService } from "~features/company/initiation-note.service";
import { InvestmentCaseService } from "~features/company/investment-case.service";
import { ShareholderDialogComponent } from "~features/company/shareholder-dialog/shareholder-dialog.component";
import { SharepriceDialogComponent } from "~features/company/shareprice-dialog/shareprice-dialog.component";
import { SwotService } from "~features/company/swot.service";
import { ValuationService } from "~features/company/valuation.service";
import { CompanyGridComponent } from "~features/company-grid/company-grid/company-grid.component";
import { TileSettingsService } from "~features/dynamic-tiles/tile-settings.service";
import { TileWrapperGridComponent } from "~features/dynamic-tiles/tile-wrapper-grid/tile-wrapper-grid.component";
import { TemplateChartComponent } from "~features/dynamic-tiles/tileparts/template-chart/template-chart.component";
import { DisplayEditorContentComponent } from "~features/editor/display-editor-content/display-editor-content.component";
import { EventService } from "~features/events/event.service";
import { FrontendConfigService } from "~features/frontend-config/frontendConfig.service";
import { SubscribeBannerComponent } from "~features/newsletter-subscription/subscribe-banner/subscribe-banner.component";
import { ButtonDownloadPdfComponent } from "~features/pdfAnalytics/button-download-pdf/button-download-pdf.component";
import { ResearchAccordionComponent } from "~features/research/research-accordion/research-accordion.component";
import { ResearchListDialogComponent } from "~features/research/research-list-dialog/research-list-dialog.component";
import { SEOService } from "~features/seo/seo.service";
import { DcfModelComponent } from "~features/tables/dcf-model/dcf-model.component";
import { FinancialsTableComponent } from "~features/tables/financials-table/financials-table.component";
import { KeyfactsComponent } from "~features/tables/keyfacts/keyfacts.component";
import { TableNotabsComponent } from "~features/tables/table-notabs/table-notabs.component";
import { Tables } from "~features/tables/tables.util";
import { VideoPlayerComponent } from "~features/video/video-player/video-player.component";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { WeeklyScreenerPageComponent } from "~pages/public/weekly-screener-page/weekly-screener-page.component";
import { HorizontalNavPanelComponent } from "~shared/components/contentful/horizontal-nav-panel/horizontal-nav-panel.component";
import { MoreContentCtaComponent } from "~shared/components/contentful/more-content-cta/more-content-cta.component";
import { ArrowIconComponent } from "~shared/components/ui/arrow-icon/arrow-icon.component";
import { DateComponent } from "~shared/components/ui/date/date.component";
import { LogoComponent } from "~shared/components/ui/logo/logo.component";
import { TabComponent } from "~shared/components/ui/tab/tab.component";
import { TabsComponent } from "~shared/components/ui/tabs/tabs.component";
import { TestChartComponent } from "~shared/components/ui/test-chart/test-chart.component";
import { INITIAL_COMPANY } from "~shared/initial-values";
import { DialogService } from "~shared/services/dialog.service";
import { getNumberColor, getTextColor } from "~utils/coloring.utils";
import { formatTVChartTicker } from "~utils/company.utils";

@Component({
  selector: "app-company-page",
  standalone: true,
  imports: [
    JsonPipe,
    DisplayEditorContentComponent,
    ResearchAccordionComponent,
    CompanyGridComponent,
    SubscribeBannerComponent,
    KeyfactsComponent,
    FinancialsTableComponent,
    TableNotabsComponent,
    DcfModelComponent,
    TabsComponent,
    DialogModule,
    TabComponent,
    TileWrapperGridComponent,
    DecimalPipe,
    ShareholdersBarchartComponent,
    HorizontalNavPanelComponent,
    RouterLink,
    SmoothscrollDirective,
    CompanyinfoSliderComponent,
    WeeklyScreenerPageComponent,
    ArrowIconComponent,
    TestChartComponent,
    LogoComponent,
    DateComponent,
    VideoPlayerComponent,
    NgClass,
    MoreContentCtaComponent,
    TemplateChartComponent,
    ButtonDownloadPdfComponent,
  ],
  templateUrl: "./company-page.component.html",
  styleUrl: "./company-page.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyPageComponent implements AfterViewInit {
  @ViewChildren("tvShareChart") chart;

  public route = inject(ActivatedRoute);
  public dialogService = inject(DialogService);
  routeParams = signal(this.route.snapshot.params);
  // Local Company Data
  companyName: WritableSignal<string> = signal("");
  // next and previous company
  previousCompany = signal(INITIAL_COMPANY);
  nextCompany = signal(INITIAL_COMPANY);
  /**
   * Tradinv view chart
   *
   */
  chartID = String(Math.floor(Math.random() * 100));
  chartWidth = 600;
  chartHeight = 300;
  chartObj: IChartApi | null = null;
  lineSeries: ISeriesApi<"Line"> | null = null;
  public companyInfoSliderIndex: WritableSignal<number> = signal(0);

  initiationNoteService = inject(InitiationNoteService);
  currentInitiationNote =
    this.initiationNoteService.getInitiationNoteResearch();
  chartTileBaseYear = signal(2023);

  tableUtils = inject(Tables);
  valuationService = inject(ValuationService);
  /**
   *  Lifecycles
   *
   *
   **/
  valuationEditorContent: Signal<IEditorContent | null> =
    this.valuationService.getValuation();
  valuationTradingMultiplesTableTemplate = "Keyfacts Multiples";
  valuationDCFTableTemplate = "Default";
  valuationFCFYieldTableTemplate = "Adj. FCF yield";
  /**
   * End of tradinv view chart
   */
  seoService = inject(SEOService);
  frontendConfigService = inject(FrontendConfigService);
  currentFrontendConfig = this.frontendConfigService.getFrontendConfig();
  lastCompanyEvent = computed(() => {
    const lastEvent = this.companyEvents()?.find((event) => {
      if (new Date(event.date) < new Date() && event.videoURL) {
        return event;
      }
    });
    return lastEvent;
  });
  baseyear: Signal<number> = computed(() => {
    return this.companySettings().baseyear;
  });
  protected readonly encodeURIComponent = encodeURIComponent;
  protected readonly encodeURI = encodeURI;
  protected readonly Object = Object;
  protected readonly getNumberColor = getNumberColor;
  protected readonly getTextColor = getTextColor;
  private companyInfoSliderSlides = 5;
  private eventService = inject(EventService);
  companyEvents = this.eventService.getCompanyEvents();
  private companyInfoService = inject(CompanyInfoService);
  private companyListService = inject(CompanylistService);
  companyList = this.companyListService.getCompanyList();
  companyListIsLoaded = this.companyListService.isLoaded;
  private companyDataService = inject(CompanydataService);
  companySettings = this.companyDataService.getCurrentCompanySettings();
  private swotService = inject(SwotService);
  swotContent = this.swotService.getSwot();
  // Company Data from Services but in local computed signals
  private investmentCaseService = inject(InvestmentCaseService);
  investmentCaseEditorContent = this.investmentCaseService.getInvestmentCase();
  private excelService = inject(ExcelService);
  valuationTextNumbers = this.excelService.getValuationTextNumbers();
  // "General" Data from Services
  excelMapping = this.excelService.getExcelMapping();
  companyNumbers = this.excelService.getCompanyNumbers();
  investorRelationsURL = computed(() => {
    const key = "ir_website";
    if (
      this.excelMapping() &&
      this.excelMapping()[key] &&
      this.companyNumbers()
    ) {
      const mappingEntry = this.excelMapping()[key];
      if (mappingEntry.type === "VALUE" || mappingEntry.type === "") {
        const entry = {
          title: mappingEntry[""],
          value: this.tableUtils.getValue(
            this.companyNumbers(),
            mappingEntry,
            "eng",
            0
          ),
        };
        return entry.value;
      } else {
        return null;
      }
    } else {
      return null;
    }
  });
  companyLiveQuotes = this.excelService.getCompanyLiveQuotes();
  companyHistoricalQuotes = this.excelService.getCompanyHistoricalQuotes();
  weeklyUpDown = computed(() => {
    return this.getChangeInPerc(
      this.companyHistoricalQuotes(),
      5,
      "adjusted_close"
    );
  });
  monthlyUpDown = computed(() => {
    return this.getChangeInPerc(
      this.companyHistoricalQuotes(),
      23,
      "adjusted_close"
    );
  });
  yearlyUpDown = computed(() => {
    return this.getChangeInPerc(
      this.companyHistoricalQuotes(),
      253,
      "adjusted_close"
    );
  });
  chartData = computed(() => {
    const quotes = this.companyHistoricalQuotes();
    const uniqueQuotes = [
      ...quotes
        .reduce((map, obj) => map.set(obj.date, obj), new Map())
        .values(),
    ];

    //TODO: we need to calculate the currencies corrected values -> see old code

    const newChartData = uniqueQuotes.map((el) => ({
      time: el.date,
      value: el.adjusted_close,
    }));
    newChartData.sort((a, b) => (a.time > b.time ? 1 : 0));
    return newChartData;
  });
  private companyTickerService = inject(CompanyTickerService);
  // Company Data from Services
  companyTicker = this.companyTickerService.getCompanyTicker();
  private companyResearchService = inject(CompanyResearchService);
  researchOverview = this.companyResearchService.getResearchOverview();
  researchArticle = this.companyResearchService.getResearchArticle();
  private copmpanyRatingService = inject(CompanyRatingService);
  private tileSettingsService = inject(TileSettingsService);
  // Tiles
  researchTileSettings = this.tileSettingsService.researchTileSettings;
  keyfiguresTileSettings = this.tileSettingsService.keyfiguresTileSettings;
  swotTileSettings = this.tileSettingsService.swotTileSettings;
  investmentCaseChartSettings =
    this.tileSettingsService.investmentCaseChartSettings;
  valuationTileSettings = this.tileSettingsService.valuationTileSettings;
  private router = inject(Router);

  constructor() {
    this.route.params.subscribe((params) => {
      this.routeParams.set(params);
    });

    effect(() => {
      this.checkIfRedirectionIsNeededThenRedirect(this.currentFrontendConfig());
    });

    this.companyName.set(this.routeParams().companyName);

    effect(() => {
      if (this.companyList() !== null && this.routeParams().companyName) {
        if (
          !this.companyList().some(
            (company) => company.name == this.routeParams().companyName
          )
        ) {
          console.log("NOT A VALID COMPANY");
          this.router.navigate([""]);
        }
      }
    });
    effect(() => {
      if (this.companyListIsLoaded() && this.companyName()) {
        const companyTicker = this.companyListService.mapTicker(
          this.companyName()
        );
        this.companyTickerService.setCompanyTicker(companyTicker);

        this.seoService.createAndSetCompanyPageMetaTags(
          this.companyName(),
          companyTicker,
          this.router.url
        );
      }
    }, enableSignalWritesInEffectContext);

    effect(() => {
      if (this.companyListIsLoaded()) {
        this.previousCompany.set(this.getAdjacentCompany(-1));
        this.nextCompany.set(this.getAdjacentCompany(1));
      }
      if (this.chart && this.companyHistoricalQuotes()?.length > 0) {
        this.updateChart();
      }
    }, enableSignalWritesInEffectContext);
  }

  checkIfRedirectionIsNeededThenRedirect(frontendConfig) {
    if (frontendConfig) {
      const renamedCompany = frontendConfig.renamedCompanies.find(
        (company) =>
          company.oldName.toLowerCase() ===
          this.routeParams().companyName.toLowerCase()
      );
      console.log(renamedCompany);
      if (renamedCompany) {
        this.router.navigate(["/companies", renamedCompany.newName]);
      }
    }
  }

  companyInfoSliderNextSlide() {
    this.companyInfoSliderIndex.update(
      (index) =>
        (index + 1 + this.companyInfoSliderSlides) %
        this.companyInfoSliderSlides
    );
  }

  companyInfoSliderPrevSlide() {
    this.companyInfoSliderIndex.update(
      (index) =>
        (index - 1 + this.companyInfoSliderSlides) %
        this.companyInfoSliderSlides
    );
  }

  updateChart() {
    if (this.chartObj) {
      if (this.lineSeries) {
        this.chartObj.removeSeries(this.lineSeries);
      }

      this.lineSeries = this.chartObj.addLineSeries({
        color: "#0F6BB2",
        lineWidth: 3,
        lastValueVisible: false,
        priceLineVisible: false,
        baseLineVisible: false,
      });

      this.lineSeries?.setData(this.chartData());

      //  console.log("CHART SERIES", this.chartData());

      if (this.lineSeries) {
        this.setTimeScale();
      } else {
        //     console.log("NO CHART DATA YET");
      }
    }
  }

  setTimeScale() {
    const startDate = new Date(Date.now());
    startDate.setFullYear(startDate.getFullYear() - 1);
    const endDate = new Date(Date.now());

    this.chartObj?.timeScale().setVisibleRange({
      from: (startDate.getTime() / 1000) as UTCTimestamp,
      to: (endDate.getTime() / 1000) as UTCTimestamp,
    });
  }

  prepareChart() {
    if (this.lineSeries) {
      this.chartObj?.removeSeries(this.lineSeries);
    }

    if (!this.chartObj) {
      const rect = this.chart.first.nativeElement.getBoundingClientRect();
      this.chartHeight = rect.height > 0 ? rect.height : 150;
      this.chartWidth = rect.width * 0.9;

      this.chartObj = createChart("tvShareChart-" + this.chartID, {
        crosshair: {},
        handleScroll: {
          mouseWheel: false,
          pressedMouseMove: false,
          horzTouchDrag: false,
          vertTouchDrag: false,
        },
        handleScale: {
          mouseWheel: false,
          pinch: false,
          axisPressedMouseMove: false,
        },
        layout: {
          background: { color: "#8ba1be", type: ColorType.Solid },
        },
        width: this.chartWidth,
        height: this.chartHeight,
        rightPriceScale: {
          visible: false,
        },
        leftPriceScale: {
          visible: true,
          autoScale: true,
        },
        timeScale: {
          visible: true,
          borderVisible: true,
          fixLeftEdge: true,
          fixRightEdge: true,
          lockVisibleTimeRangeOnResize: true,
        },
        grid: {
          horzLines: {
            visible: false,
          },
          vertLines: {
            visible: false,
          },
        },
      });

      this.lineSeries = this.chartObj.addLineSeries({
        color: "#0F6BB2",
        lineWidth: 3,
        lastValueVisible: false,
        priceLineVisible: false,
        baseLineVisible: false,
      });
    }
  }

  ngAfterViewInit() {
    this.prepareChart();
    //console.log("AFTER VIEW INIT");
  }

  openBookAMeetingComponent() {
    this.dialogService.open(
      BookAMeetingComponent,
      {
        maxWidth: "80%",
        data: {
          companyTicker: this.companyTicker,
          companySettings: this.companySettings,
        },
      },
      "dialog-panel-gray-alster"
    );
  }

  openResearchList() {
    this.dialogService.open(
      ResearchListDialogComponent,
      this.researchOverview,
      "dialog-panel-gray-alster"
    );
  }

  openSharePrice() {
    this.dialogService.open(
      SharepriceDialogComponent,
      {
        maxWidth: "80%",
        data: {
          mappedTVChartTicker: this.companySettings()?.mappedTVChartTicker
            ? this.companySettings()?.mappedTVChartTicker
            : formatTVChartTicker(this.companySettings()?.companyTicker),
        },
      },
      "dialog-panel-gray-alster"
    );
  }

  openShareHolders() {
    this.dialogService.open(
      ShareholderDialogComponent,
      {
        maxWidth: "80%",
        data: {
          companyNumbers: this.companyNumbers,
          baseyear: this.baseyear,
        },
      },
      "dialog-panel-gray-alster"
    );
  }

  getChangeInPerc(
    data: Array<{ adjusted_close: number; close: number }>,
    offset: number,
    field: string
  ) {
    if (data.length > offset + 1) {
      return (
        ((data[data.length - 1][field] -
          data[data.length - offset - 1][field]) /
          data[data.length - offset - 1][field]) *
        100
      );
    } else {
      return null;
    }
  }

  private getAdjacentCompany(offset: number) {
    if (this.companyList()) {
      const currentIndex = this.companyList().findIndex(
        (company) => company.companyTicker === this.companyTicker()
      );

      const adjacentIndex =
        (currentIndex + offset + this.companyList().length) %
        this.companyList().length;

      return this.companyList()[adjacentIndex];
    } else {
      return null;
    }
  }
}
