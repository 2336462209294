import { HttpClient } from "@angular/common/http";
import { inject, Injectable, signal, WritableSignal } from "@angular/core";
import { catchError, finalize, map } from "rxjs/operators";
import { IApiResponse } from "~global-interfaces/IApiResponse.";
import { ILegalDisclaimerCompany } from "~global-interfaces/ILegalDisclaimerCompany";

import { alsterAPI_URL } from "~api/api-urls";
import { AdminRequestService } from "~shared/services/admin-request.service";
import { ErrorHandlingService } from "~shared/services/error-handling.service";
import { LoadingIndicatorService } from "~shared/services/loading-indicator.service";

@Injectable({
  providedIn: "root",
})
export class LegalDisclaimerService {
  isLoading: WritableSignal<boolean> = signal(false);
  adminRequestService = inject(AdminRequestService);
  errorHandlingService = inject(ErrorHandlingService);
  loadingIndicatorService = inject(LoadingIndicatorService);
  private http = inject(HttpClient);
  private legalDisclaimerCompanies: WritableSignal<ILegalDisclaimerCompany[]> =
    signal(null);
  private currentLegalDisclaimerCompany: WritableSignal<ILegalDisclaimerCompany | null> =
    signal(null);

  constructor() {
    this.fetchLegalDisclaimerCompanies();
  }

  fetchLegalDisclaimerCompanies() {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<ILegalDisclaimerCompany[]>>(
        alsterAPI_URL + "legaldisclaimers?limit=1000&page=1"
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "fetch legal disclaimer companies"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (legalDisclaimerCompanies) => {
          this.legalDisclaimerCompanies.set(legalDisclaimerCompanies);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch legal disclaimer companies  success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  getisLoading() {
    return this.isLoading;
  }
  fetchLegalDisclaimerCompany(legalDisclaimerCompanyId) {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<ILegalDisclaimerCompany>>(
        alsterAPI_URL + "legaldisclaimers/" + legalDisclaimerCompanyId
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "fetch legal disclaimer company"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (legalDisclaimerCompany) => {
          this.currentLegalDisclaimerCompany.set(legalDisclaimerCompany);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch legal disclaimer company success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  updateLegalDisclaimerCompany(newData) {
    this.isLoading.set(true);
    const body = { ...newData };
    this.http
      .put<IApiResponse<ILegalDisclaimerCompany[]>>(
        alsterAPI_URL + "legaldisclaimers/edit/" + newData._id,
        body
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "update legal disclaimer company"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (legalDisclaimerCompanies) => {
          this.legalDisclaimerCompanies.set(legalDisclaimerCompanies);
          this.adminRequestService.setLastRequestMessage("3", true);
          this.adminRequestService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  createLegalDisclaimerCompany(
    newData = {
      bloomberg: "placeholder",
      disclosures: "placeholder",
      ticker: "placeholder",
      name: "placeholder",
    }
  ) {
    this.isLoading.set(true);
    const body = { ...newData };
    this.http
      .post<IApiResponse<ILegalDisclaimerCompany[]>>(
        alsterAPI_URL + "legaldisclaimers/create",
        body
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "create legal disclaimer company"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (legalDisclaimerCompanies) => {
          this.legalDisclaimerCompanies.set(legalDisclaimerCompanies);
          this.adminRequestService.setLastRequestMessage("15", true);
          this.adminRequestService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  getLegalDisclaimerCompanies() {
    return this.legalDisclaimerCompanies;
  }
  getCurrentLegalDisclaimerCompany() {
    return this.currentLegalDisclaimerCompany;
  }
}
