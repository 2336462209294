<div class="bg-gray-alster text-white">
  <div class="px-4 py-3 text-md font-normal tracking-widest uppercase">
    Research
  </div>
  <div class="px-4 py-4">
    <div class="text-center mb-4">
      <a
        href="{{ researchOverviewList()[0]?.pdf_DE?.downloadURL }}"
        target="_blank"
      >
        <button
          class="bg-blue-research hover:bg-blue-600 py-2 px-4 uppercase tracking-widest"
        >
          Download the Latest Research
        </button>
      </a>
    </div>

    <div class="grid grid-cols-5 gap-2 py-4">
      <div class="col-span-1">Release date</div>
      <div class="col-span-3">Title</div>
      <div class="col-span-1"></div>
    </div>

    <hr class="w-full" />
    <div class="divide-y divide-gray-200 overflow-y-auto max-h-96">
      @for (item of researchOverviewList(); track item; let index = $index) {
        <div class="grid grid-cols-5 gap-2 py-2">
          <div class="col-span-1 flex items-center">
            <app-date
              [date]="item.published_date"
              [formatType]="'dd.MM.yyyy'"
            ></app-date>
          </div>
          <div class="col-span-3 font-normal flex items-center">
            {{ item.title }}
          </div>
          <div class="col-span-1 text-right">
            <app-button-download-pdf
              [buttonClasses]="
                'bg-transparent hover:bg-gray-300 font-normal py-2 px-4 rounded uppercase tracking-widest hover:cursor-pointer'
              "
              [pdf]="item.pdf_DE"
            ></app-button-download-pdf>
          </div>
        </div>
      }
    </div>
  </div>
</div>
