import { JsonPipe } from "@angular/common";
import { Component, computed, inject, OnInit, Signal } from "@angular/core";
import { forkJoin } from "rxjs";
import { IEvent } from "~global-interfaces/IEvent";

import { AuthService } from "~features/auth/auth.service";
import { CompanyOverviewComponent } from "~features/company/company-overview/company-overview.component";
import { CustomerEventGridComponent } from "~features/customer-dashboard/customer-event-grid/customer-event-grid.component";
import { InvestorNewsletterComponent } from "~features/customer-dashboard/customer-newsletter/investor-newsletter.component";
import { EventGridQuickmodeComponent } from "~features/customer-dashboard/event-grid-quickmode/event-grid-quickmode.component";
import { InvestorProfileViewComponent } from "~features/customer-dashboard/investor-profile-view/investor-profile-view.component";
import { EventService } from "~features/events/event.service";
import { UserService } from "~features/users/user.service";
import { LoadingIndicatorComponent } from "~shared/components/ui/loading-indicator/loading-indicator.component";
import { TabComponent } from "~shared/components/ui/tab/tab.component";
import { TabsComponent } from "~shared/components/ui/tabs/tabs.component";

@Component({
  selector: "app-customer-dashboard-page",
  templateUrl: "./customer-dashboard-page.component.html",
  styleUrls: ["./customer-dashboard-page.component.scss"],
  standalone: true,
  imports: [
    LoadingIndicatorComponent,
    EventGridQuickmodeComponent,
    CompanyOverviewComponent,
    InvestorNewsletterComponent,
    InvestorProfileViewComponent,

    TabsComponent,

    TabComponent,
    JsonPipe,
    CustomerEventGridComponent,
  ],
})
export class CustomerDashboardPageComponent implements OnInit {
  needsBetterProfile = false;
  expanded = [];

  loadingNewData = true;
  private userService = inject(UserService);
  userInvestorProfile = this.userService.getInvestorProfile();
  private today = new Date().setHours(new Date().getHours() - 2);
  nextEvent: Signal<IEvent[] | null> = computed(() => {
    if (this.userRegisteredEvents()?.length > 0) {
      return [
        this.userRegisteredEvents().find(
          (event) => new Date(event.date).getTime() > this.today
        ),
      ];
    } else {
      return null;
    }
  });
  pastEvents = computed(() => {
    if (this.userRegisteredEvents()?.length > 0) {
      return this.userRegisteredEvents().filter(
        (event) => new Date(event.date).getTime() < this.today
      );
    } else {
      return [];
    }
  });
  upcomingEvents = computed(() => {
    if (this.userRegisteredEvents()?.length > 0) {
      const filteredUpcomingEventsWithoutNextEvent =
        this.userRegisteredEvents().filter(
          (event) => new Date(event.date).getTime() > this.today
        );
      filteredUpcomingEventsWithoutNextEvent.shift();
      return filteredUpcomingEventsWithoutNextEvent;
    } else {
      return [];
    }
  });
  private eventService = inject(EventService);
  userRegisteredEvents = this.eventService.getUserRegisteredEvents();
  private authService = inject(AuthService);

  constructor() {}

  ngOnInit(): void {
    const usermail = this.authService.getCurrentUserName();
    const savedEvent = this.loadEventInfoForLaterSubscritionViaDashboard();
    const savedNewsletters = this.loadSavedNewsletters() as Array<string>;
    let autoSubtionsToEventsAndNewsletters: any[];
    const eventAutoSubscriptions = [];
    let newsLetterAutoSubscriptions = [];
    if (savedEvent) {
      eventAutoSubscriptions.push(
        this.eventService.registerForEvent(usermail, savedEvent.sibListId)
      );
    }
    if (savedNewsletters) {
      newsLetterAutoSubscriptions = savedNewsletters.map((sibListId) =>
        this.eventService.registerForEvent(usermail, sibListId)
      );
    }
    autoSubtionsToEventsAndNewsletters = [
      ...eventAutoSubscriptions,
      ...newsLetterAutoSubscriptions,
    ];

    // initData is supposed to run when this is empty, but it does not....
    if (autoSubtionsToEventsAndNewsletters.length > 0) {
      forkJoin(autoSubtionsToEventsAndNewsletters).subscribe((results) => {
        localStorage.removeItem("eventforuseaftersignup");
        localStorage.removeItem("newslettersforuseaftersignup");
      });
    }
  }

  loadEventInfoForLaterSubscritionViaDashboard() {
    const savedEvent = window.localStorage.getItem("eventforuseaftersignup");
    return JSON.parse(savedEvent);
  }

  loadSavedNewsletters() {
    const savedNewsletters = window.localStorage.getItem(
      "newslettersforuseaftersignup"
    );
    if (savedNewsletters) {
      return JSON.parse(savedNewsletters);
    } else {
      return null;
    }
  }

  initData() {
    // console.log(
    //   "init data from customer dashboard page -> not needed anymore??"
    // );
  }
}
