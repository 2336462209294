import { HttpClient } from "@angular/common/http";
import { inject, Injectable, signal } from "@angular/core";
import { catchError, finalize, map, tap } from "rxjs/operators";
import { IApiResponse } from "~global-interfaces/IApiResponse.";
import { IQueryParams } from "~global-interfaces/IQueryParams";

import { AdminRequestService } from "~shared/services/admin-request.service";
import { ErrorHandlingService } from "~shared/services/error-handling.service";

@Injectable({
  providedIn: "root",
})
export class PaginationService<T> {
  items = signal<T[]>([]);
  totalCount = signal<number>(0);
  adminRequestService = inject(AdminRequestService);
  errorHandlingService = inject(ErrorHandlingService);

  constructor(public http: HttpClient) {}

  fetchItems(url: string, paginationParams: IQueryParams) {
    const {
      page,
      limit,
      customerType,
      afterdate,
      beforedate,
      hidden,
      mailsearchterm,
      year,
      quarter,
      companyTicker,
      titleSearchTerm,
      videomustexist,
      searchterm,
    } = paginationParams;
    const queryParams = `page=${page}&limit=${limit}${
      customerType ? `&customerType=${customerType}` : ""
    }${afterdate ? `&afterdate=${afterdate}` : ""}${
      beforedate ? `&beforedate=${beforedate}` : ""
    }${hidden ? `&hidden=${hidden}` : ""}${
      mailsearchterm ? `&mailSearchTerm=${mailsearchterm}` : ""
    }${year ? `&year=${year}` : ""}
  ${quarter ? `&quarter=${quarter}` : ""}
  ${companyTicker ? `&companyTicker=${companyTicker}` : ""}
  ${titleSearchTerm ? `&titleSearchTerm=${titleSearchTerm}` : ""}${
      videomustexist ? `&videomustexist=${videomustexist}` : ""
    }${searchterm ? `&searchterm=${searchterm}` : ""}
  
    `;
    // TODO: check subscribe function to fill tap

    return this.http.get<IApiResponse<T[]>>(`${url}?${queryParams}`).pipe(
      map((response) => ({
        items: response.data[0],
        totalCount: response.totalCount,
      })),

      catchError((error) =>
        this.errorHandlingService.handleError(error, "fetch paginated items")
      ),
      finalize(() => {})
    );
  }

  public getTotalCount() {
    return this.totalCount;
  }
}
