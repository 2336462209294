import { Component, inject, signal, WritableSignal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IEvent } from "~global-interfaces/IEvent";

import { AdminEventEditorFormComponent } from "~features/events/admin/admin-event-editor-form/admin-event-editor-form.component";
import { EventService } from "~features/events/event.service";
import { FileUploadComponent } from "~features/file-manager/file-upload/file-upload.component";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { DeleteButtonComponent } from "~shared/components/ui/delete-button/delete-button.component";

@Component({
  selector: "app-admin-event-editor",
  standalone: true,
  imports: [
    BreadcrumpComponent,
    AdminEventEditorFormComponent,
    FileUploadComponent,
    AdminSidePanelComponent,
    DeleteButtonComponent,
  ],
  templateUrl: "./admin-event-editor.component.html",
  styleUrls: ["./admin-event-editor.component.scss"],
})
export class AdminEventEditorComponent {
  eventService = inject(EventService);
  event = this.eventService.getCurrentEvent();
  showDeletionWarning: WritableSignal<boolean> = signal(false);

  constructor(private route: ActivatedRoute) {
    const eventId = this.route.snapshot.paramMap.get("eventid") ?? "";
    this.eventService.fetchEvent(eventId);
  }

  deleteEvent(_id) {
    console.log(_id);
    //TODO this.eventService.deleteEvent(_id)
  }

  toggleDeletionWarning(showWarning: boolean) {
    this.showDeletionWarning.set(showWarning);
  }
  onFormSubmit(newData: IEvent) {
    // console.log(newData);
    this.eventService.updateEvent(newData);
  }
}
