<cdk-accordion class="">
  @for (item of displayedItems(); track item; let index = $index) {
    <cdk-accordion-item
      #accordionItem="cdkAccordionItem"
      role="button"
      tabindex="0"
      [attr.id]="'accordion-header-' + index"
      [attr.aria-expanded]="accordionItem.expanded"
      [attr.aria-controls]="'accordion-body-' + index"
    >
      <div
        class="flex flex-wrap items-center my-4 lg:my-1 py-1 border-t-1 border-[#c7e7fd] hover:underline"
        (click)="accordionItem.toggle(); loadResearchArticle(item.title)"
      >
        <div class="w-8 lg:mr-2 flex-shrink-0">
          @if (accordionItem.expanded) {
            <i class="fa fa-chevron-up text-blue-500"></i>
          } @else {
            <i class="fa fa-chevron-down text-blue-500"></i>
          }
        </div>
        <div class="lg:mr-2 text-sm lg:text-base font-medium flex-shrink-0">
          <app-date
            [date]="item.published_date"
            [formatType]="'dd.MM.yyyy'"
          ></app-date>
        </div>
        <div
          class="text-sm lg:text-base font-medium leading-[1.4] lg:mr-2 flex-grow line-clamp-1 lg:line-clamp-none"
        >
          {{ item.title }}
        </div>
        <div class="hidden lg:flex flex-shrink-0 mt-1 sm:mt-0">
          <app-button-download-pdf
            [pdf]="item.pdf_DE"
            [buttonClasses]="
              'hover:underline text-blue-500 tracking-widest uppercase font-medium text-sm lg:text-base '
            "
          ></app-button-download-pdf>
        </div>
      </div>
      <div
        class="bg-blue-100 p-6"
        role="region"
        [style.display]="accordionItem.expanded ? '' : 'none'"
        [attr.id]="'accordion-body-' + index"
        [attr.aria-labelledby]="'accordion-header-' + index"
      >
        <app-display-editor-content
          class="cursor-auto"
          [content]="currentResearchArticle().content_DE"
        ></app-display-editor-content>
      </div>
    </cdk-accordion-item>
  }
</cdk-accordion>

<hr />

<div class="flex justify-center mt-4">
  @if (remainingItemsCount() > 0) {
    <button
      (click)="toggleShowAll()"
      class="tracking-widest uppercase text-sm lg:text-base font-medium text-blue-500 hover:text-blue-700"
    >
      <i
        class="fa"
        [ngClass]="showAllItems() ? 'fa-chevron-up' : 'fa-chevron-down'"
      ></i>
      <span>{{
        showAllItems()
          ? "Show Less"
          : "Show All (" + remainingItemsCount() + " more)"
      }}</span>
    </button>
  }
</div>
