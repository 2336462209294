<h4 class="text-md font-bold flex justify-between items-center mb-4">
  <span>Details</span>
  <span>Register / Unregister</span>
</h4>

@if (mergedEventList().length > 0) {
  @for (comingEvent of mergedEventList(); track comingEvent; let i = $index) {
    <div
      class="mb-6 bg-blue-100 border-2 rounded-[20px] overflow-hidden shadow-md border-gray-alster px-4 h-28"
    >
      <div class="flex flex-col sm:flex-row h-full">
        <!-- Date column -->
        <div
          class="bg-blue-dark-alster text-black p-2 flex flex-row sm:flex-col justify-between sm:justify-center items-center leading-none h-full"
        >
          <div class="flex flex-col items-center justify-center w-20 h-full">
            <h5 class="uppercase m-0">{{ comingEvent.date | date: "MMM" }}</h5>
            <h3 class="m-0 font-semibold">
              {{ comingEvent.date | date: "dd" }}
            </h3>
            <h5 class="text-md">{{ comingEvent.date | date: "yyyy" }}</h5>
            <h5 class="text-md">{{ comingEvent.date | date: "HH:mm" }}</h5>
          </div>
        </div>

        <!-- Content column -->
        <div class="flex-grow p-2">
          <h4 class="text-md font-semibold mb-2 e2e-event-title">
            {{ comingEvent.title }}
          </h4>

          @if (comingEvent.speakers && comingEvent.speakers.length > 0) {
            <div class="mt-3 space-y-2">
              <div class="flex items-center">
                <div
                  class="w-14 h-14 rounded-full overflow-hidden mr-3 flex-shrink-0"
                >
                  @if (comingEvent.speakers[0]?.imageURL) {
                    <img
                      [src]="comingEvent.speakers[0]?.imageURL"
                      class="w-full h-full object-cover"
                      alt="Speaker"
                    />
                  }
                </div>
                <div class="text-sm">
                  <p class="font-semibold">
                    {{ comingEvent.speakers[0].name }}
                  </p>
                  <p class="text-gray-600">
                    {{ comingEvent.speakers[0]?.position }}
                  </p>
                </div>
              </div>
            </div>
          }
        </div>

        <!-- Checkbox column -->
        <div class="p-4 flex items-center justify-end sm:w-24">
          <label class="flex items-center cursor-pointer">
            <input
              class="form-checkbox h-7 w-7 text-blue-500 rounded border-gray-alster border-2 focus:ring-blue-500"
              id="{{ 'Id' + i }}"
              type="checkbox"
              [checked]="comingEvent.isRegistered"
              (change)="handleSubscribeToggle(comingEvent.sibListId)"
            />
          </label>
        </div>
      </div>
    </div>
  }
}
