import { NgClass, ViewportScroller } from "@angular/common";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-horizontal-nav-panel",
  templateUrl: "./horizontal-nav-panel.component.html",
  styleUrls: ["./horizontal-nav-panel.component.scss"],
  standalone: true,
  imports: [NgClass],
})
export class HorizontalNavPanelComponent implements OnInit {
  lastScrollPositionAnchor = "investmentcase";
  public navigationPoints = [
    {
      name: "Overview",
      jumpmark: "overview",
      inView: true,
    },
    {
      name: "Research",
      jumpmark: "research",
      inView: false,
    },
    {
      name: "Key Figures",
      jumpmark: "keyfigures",
      inView: false,
    },
    {
      name: "Investment Case",
      jumpmark: "investmentcase",
      inView: false,
    },
    {
      name: "SWOT-Analysis",
      jumpmark: "swot",
      inView: false,
    },
    {
      name: "Valuation",
      jumpmark: "valuation",
      inView: false,
    },
    {
      name: "Financials",
      jumpmark: "financials",
      inView: false,
    },
  ];

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private cdr: ChangeDetectorRef,
  ) {
    this.viewportScroller.setOffset([250, 250]);
  }

  scrollIntoView(target) {
    if (target !== "overview") {
      this.viewportScroller.scrollToAnchor(target);
    } else {
      this.viewportScroller.scrollToPosition([0, 0]);
    }
  }

  ngOnInit(): void {
    window.addEventListener("scroll", () => {
      setTimeout(() => this.checkIfInView(), 1);
    });
  }

  checkIfInView() {
    for (const item of this.navigationPoints) {
      const elem = document.getElementById(item.jumpmark);
      if (elem) {
        const bounding = elem.getBoundingClientRect();

        if (item.jumpmark === "overview") {
          if (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom * 0.5 <=
              (window.innerHeight || document.documentElement.clientHeight)
          ) {
            this.lastScrollPositionAnchor = item.jumpmark;
            item.inView = true;
          } else {
            item.inView = false;
          }
        } else {
          if (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <=
              (window.innerHeight || document.documentElement.clientHeight)
          ) {
            this.lastScrollPositionAnchor = item.jumpmark;
            item.inView = true;
          } else {
            item.inView = false;
          }
        }
      }
    }

    if (
      this.navigationPoints
        .map((item) => item.inView)
        .every((value) => value === false)
    ) {
      this.navigationPoints.map((item) => {
        if (item.jumpmark === this.lastScrollPositionAnchor) {
          item.inView = true;
        }
      });
    }

    this.cdr.detectChanges();
  }
}
