import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import { JsonPipe, KeyValue, KeyValuePipe, NgFor } from "@angular/common";
import { Component, Inject } from "@angular/core";

@Component({
  selector: "app-investor-profile-dialog",
  standalone: true,
  imports: [NgFor, JsonPipe, KeyValuePipe],
  templateUrl: "./investor-profile-dialog.component.html",
})
export class InvestorProfileDialogComponent {
  investorProfileMapping = {
    aum: {
      title: "Assets under Management (EURm)",
      values: ["< 50", "50 - 200", "200 - 500", "> 500"],
    },
    size: {
      title: "Segment",
      values: ["Small & Mid Cap", "Large Cap", "All Cap"],
    },
    holdingPeriod: {
      title: "Holding Period (years)",
      values: ["< 1", "1 -3", "> 3"],
    },
    investmentStyle: {
      title: "Investment Style",
      values: ["Growth", "Value", "GARP", "ESG", "agnostic"],
    },
    investorType: {
      title: "Investor Type",
      values: [
        "Asset-/Portfolio Manager",
        "Independent Financial Adviser",
        "Family Office",
        "Presse / Börsenbrief",
        "Corporate",
        "Other",
      ],
    },
    region: {
      title: "Region",
      values: ["Germany", "Europe", "Worldwide"],
    },
    ticketSize: {
      title: "Ticket Size (EURk)",
      values: ["< 50", "50 - 250", "> 250"],
    },
    description: {
      title: "Further Information",
      values: [],
    },
  };

  person: any;
  investorProfile: any;

  constructor(
    public dialogRef: DialogRef<boolean>,
    @Inject(DIALOG_DATA) public data: any
  ) {
    this.person = data.data;
    this.investorProfile = data.data.investorProfile;
  }

  profileHasDetails() {
    const profileDetails = [
      "aum",
      "size",
      "holdingPeriod",
      "investmentStyle",
      "region",
      "ticketSize",
    ];
    return Object.keys(this.person.investorProfile).some(
      (r) => profileDetails.indexOf(r) >= 0
    );
  }

  keyAscOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return a.key > b.key ? 1 : b.key > a.key ? -1 : 0;
  };
}
