/**
 *
 * All the profile keys and possible values are hardcoded.
 * That is not so good, but we want to keep it simple for now and those keys are not likely to change.
 *
 *
 */

export interface AlsterUser {
  _id?: string;
  userId: string;
  userName: string;
  customerType: string; // investor, presse, emittent, etc.
  userRole: string; // admin, customer, etc.
  password: string;
  addinIds: string[];
  rights: UserRights;
  editing: any;
  token: string;
}

export interface UserRights {
  companies: CompanyRights[];
  adminPanel: boolean;
  reportPanel: boolean;
}

export interface CompanyRights {
  companyId: string;
  write: boolean;
  upload: boolean;
  download: boolean;
}

// this model is for better readability, it does not really needs to be implemented
export interface CustomerUser extends AlsterUser {}

export interface NewUser {
  email: string;
  password: string;
  userRole: string;
}

export interface NewContact extends InvestorProfile {
  VORNAME: string;
  NACHNAME: string;
  customerType: string;
  CITY: string;
  COMPANY_NAME: string;
}

export enum PossibleCustomerTypes {
  InvestorA = "Asset Manager / Portfolio Manager",
  InvestorB = "Independent Financial Adviser",
  InvestorC = "Family Office",
  Presse = "Financial press",
  Emittent = "Corporate",
  Other = "Other",
}

export enum PossibleUserRoles {
  Admin = "admin",
  Customer = "customer",
  Contributor = "contributor",
}
export enum PossibleInvestorTypes {
  InvestorA = "Asset Manager / Portfolio Manager",
  InvestorB = "Independent Financial Adviser",
  InvestorC = "Family Office",
}

export interface InvestorProfile {
  PROFIL_AUM: string;
  PROFIL_GROESSE: string;
  PROFIL_HALTEDAUER: string;
  PROFIL_INVESTITIONSSTIL: string;
  PROFIL_INVESTORENTYP?: string;
  PROFIL_REGION: string;
  PROFIL_TICKET_SIZE: string;
  PROFIL_FREITEXT: string;
}

export class InvestorProfileClass implements Partial<InvestorProfile> {
  constructor(
    public PROFIL_AUM = "",
    public PROFIL_GROESSE = "",
    public PROFIL_HALTEDAUER = "",
    public PROFIL_INVESTITIONSSTIL = "",
    public PROFIL_INVESTORENTYP = "",
    public PROFIL_REGION = "",
    public PROFIL_TICKET_SIZE = ""
  ) {
    //public PROFIL_FREITEXT = ""
  }
}

export const Countries = [
  "Germany",
  "France",
  "UK",
  "Austria",
  "Australia",
  "Switzerland",
  "Finland",
  "Denmark",
  "Netherlands",
  "Spain",
  "Italy",
  "USA",
  "India",
  "Luxembourg",
  "Belgium",
  "Sweden",
  "Norway",
  "Poland",
  "Czech_Republic",
  "Canada",
  "Bulgaria",
  "Slovenia",
  "Ireland",
  "Romania",
  "Singapore",
  "Thailand",
  "China",
  "Liechtenstein",
  "Greece",
  "Kuwait",
  "Portugal",
  "New Zealand",
  "Estonia",
  "Kyiv",
];

export const AUM = [
  "up to EUR 50m",
  "over EUR 50m up to EUR 200m",
  "over EUR 200m uo tp EUR 500m",
  "over EUR 500m",
];
export const TicketSize = [
  "up to EUR 50k",
  "over EUR 50k up to EUR 250k",
  "over EUR 250k",
];
export const Size = [
  "Small & Mid Cap (up to EUR 2 bn)",
  "Large Cap (over EUR 2 bn)",
  "All Cap (no restriction)",
];
export const Region = ["Germany", "Europe", "Worldwide"];
export const InvestorStil = [
  "Growth",
  "Value",
  "GARP",
  "ESG",
  "agnostic / special sit / opportunistic",
];
export const Haltedauer = ["up to 1 year", "1 to 3 years", "over 3 years"];
