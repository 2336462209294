<div class="container mx-auto">
  <ng-template #rt let-r="result" let-t="term">
    <a class="uppercase screener__typeahead-link">
      {{ r[language] }}
    </a>
  </ng-template>
  <div class="grid grid-cols-1">
    <div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div class="mt-1">
          <label class="text-lg font-bold">Sector:</label>
          <div class="inline-block w-full">
            <select
              [(ngModel)]="currentScreen.sector"
              class="w-full bg-white border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              id="sectorToggle"
            >
              {{
                currentScreen.sector
              }}
              @for (sector of sectors; track sector) {
                <option [value]="sector">
                  {{ sector }}
                </option>
              }
            </select>
          </div>
        </div>
        <div class="mt-1">
          <label class="text-lg font-bold">Year:</label>
          <div class="inline-block w-full">
            <select
              [(ngModel)]="currentScreen.year"
              class="w-full bg-white border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              id="yearToggle"
            >
              {{
                currentScreen.year
              }}
              @for (year of years; track year) {
                <option [value]="year">
                  {{ year }}
                </option>
              }
            </select>
          </div>
        </div>

        <div class="mt-1">
          <label class="text-lg font-bold">Country:</label>
          <div class="inline-block w-full">
            <select
              [(ngModel)]="currentScreen.country"
              class="w-full bg-white border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              id="yearToggle"
            >
              {{
                currentScreen.country
              }}
              @for (country of countries; track country) {
                <option [value]="country">
                  {{ country }}
                </option>
              }
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-8">
      <p class="text-lg font-bold">
        Popular search criteria by other investors:
      </p>
      <div class="grid grid-cols-5 gap-4">
        @for (
          item of keyNamesForButtons | keyvalue;
          track item;
          let i = $index
        ) {
          <div class="flex justify-center text-center w-full min-h-20">
            <button
              class="hover:bg-gray-400 text-gray-800 p-1 w-full uppercase"
              (click)="addSearchKey(item)"
              [ngClass]="{
                'bg-blue-400': item.value,
                'bg-gray-two': !item.value
              }"
            >
              {{ mapping[item.key][language] || "..." }}
            </button>
          </div>
        }
      </div>
    </div>

    <div class="my-6">
      <!--      TODO: Add typeahead search here, just like company search but with other-->
      <!--      content and click behavior-->
      <!--      <input-->
      <!--        id="typeahead-basic"-->
      <!--        type="text"-->
      <!--        class="w-full border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"-->
      <!--        placeholder="Add more filter criteria..."-->
      <!--        [ngbTypeahead]="search"-->
      <!--        (selectItem)="addSearchKeyWithTypeahead($event)"-->
      <!--        [resultTemplate]="rt"-->
      <!--        (click)="click$.next($any($event).target.value)"-->
      <!--        (focus)="focus$.next($any($event).target.value)"-->
      <!--      />-->

      <input
        #inputElement
        (cdkMenuOpened)="focusInput()"
        (input)="onSearchInput($event)"
        [cdkMenuTriggerFor]="mappingKeyMenu"
        class="px-3 py-2 text-base border border-gray-300 cursor-text w-full"
        placeholder="FIND COMPANY"
        type="text"
      />
      <ng-template #mappingKeyMenu>
        <div
          cdkMenu
          class="flex flex-col bg-white border border-gray-300 rounded-md py-2 max-h-[50vh] h-full overflow-auto"
        >
          @for (key of searchResults(); track key) {
            <a
              class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              cdkMenuItem
              (click)="addSearchKey(key)"
            >
              {{ key.eng }}
            </a>
          }
        </div>
      </ng-template>
    </div>

    <div>
      <table class="w-full table-auto">
        <tbody>
          @for (query of currentScreen.queries; track query; let i = $index) {
            <tr class="{{ i % 2 === 0 ? 'bg-gray-100' : 'bg-gray-200' }}">
              <td class="py-2 px-4">{{ query.name }}</td>
              <td class="py-2 px-4">
                <div class="flex items-center">
                  <label class="mr-2">Above</label>
                  <input
                    class="w-24 border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    type="number"
                    name="gte"
                    id="gte"
                    placeholder=""
                    [(ngModel)]="query.$gte"
                  />
                </div>
              </td>
              <td class="py-2 px-4">
                <div class="flex items-center">
                  <label class="mr-2">Below</label>
                  <input
                    class="w-24 border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    type="number"
                    name="lte"
                    id="lte"
                    placeholder=""
                    [(ngModel)]="query.$lte"
                  />
                </div>
              </td>
              <td class="py-2 px-4">
                <div
                  class="flex items-center justify-center w-6 h-6 bg-red-500 text-white rounded-full cursor-pointer"
                  (click)="deleteQueryObj(i)"
                >
                  <strong>&#10005;</strong>
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
    <div class="mt-6">
      @if (!loadingScreen) {
        <button class="btn" (click)="getScreen()">Search</button>
      }
      @if (loadingScreen) {
        <button class="btn" disabled>Loading...</button>
      }
      @if (showError) {
        <span class="ml-2 text-sm text-red-500"
          >An error occurred. Please try again.</span
        >
      }
      <button
        (click)="parseToCSV()"
        [hidden]="companies.length <= 0"
        class="float-right text-blue-500 hover:text-blue-600"
      >
        Download CSV <i class="fas fa-arrow-right"></i>
      </button>
    </div>

    <!--    <div>-->
    <!--      <table class="w-full table-auto">-->
    <!--        <tbody>-->
    <!--          @for (query of currentScreen.queries; track query; let i = $index) {-->
    <!--            <tr class="{{ i % 2 === 0 ? 'bg-gray-100' : 'bg-gray-200' }}">-->
    <!--              <td class="py-2 px-4">{{ query.name }}</td>-->
    <!--              <td class="py-2 px-4">-->
    <!--                <div class="flex items-center">-->
    <!--                  <label class="mr-2">Above</label>-->
    <!--                  <input-->
    <!--                    class="w-24 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"-->
    <!--                    type="number"-->
    <!--                    name="gte"-->
    <!--                    id="gte"-->
    <!--                    placeholder=""-->
    <!--                    [(ngModel)]="query.$gte"-->
    <!--                  />-->
    <!--                </div>-->
    <!--              </td>-->
    <!--              <td class="py-2 px-4">-->
    <!--                <div class="flex items-center">-->
    <!--                  <label class="mr-2">Below</label>-->
    <!--                  <input-->
    <!--                    class="w-24 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"-->
    <!--                    type="number"-->
    <!--                    name="lte"-->
    <!--                    id="lte"-->
    <!--                    placeholder=""-->
    <!--                    [(ngModel)]="query.$lte"-->
    <!--                  />-->
    <!--                </div>-->
    <!--              </td>-->
    <!--              <td class="py-2 px-4">-->
    <!--                <div-->
    <!--                  class="flex items-center justify-center w-6 h-6 bg-red-500 text-white rounded-full cursor-pointer"-->
    <!--                  (click)="deleteQueryObj(i)"-->
    <!--                >-->
    <!--                  <strong>&#10005;</strong>-->
    <!--                </div>-->
    <!--              </td>-->
    <!--            </tr>-->
    <!--          }-->
    <!--        </tbody>-->
    <!--      </table>-->
    <!--    </div>-->

    <div class="grid grid-cols-1">
      @if (companies.length > 0) {
        <div class="container my-6">
          <div class="mx-auto mt-2 overflow-x-auto">
            <table class="w-full table-auto">
              <tbody>
                <tr>
                  <th
                    class="w-64 py-2 px-4 bg-gray-100 hover:bg-gray-200 cursor-pointer"
                  >
                    <div class="flex items-center">
                      Company Name
                      <i class="ml-1 fas fa-sort"></i>
                    </div>
                  </th>
                  @for (
                    query of completedScreen.queries;
                    track query;
                    let i = $index
                  ) {
                    <th
                      class="py-2 px-4 bg-gray-100 hover:bg-gray-200 cursor-pointer"
                    >
                      <div
                        class="flex justify-center items-center"
                        (click)="sortCompanies(query.key, i, false)"
                      >
                        {{ query.name }}
                        <i class="ml-1 fas {{ getSortIcon(query.key) }}"></i>
                      </div>
                    </th>
                  }
                </tr>
                @for (company of companies; track company) {
                  <tr class="{{ $even ? 'bg-gray-50' : 'bg-white' }}">
                    <td class="w-64 py-2 px-4">
                      <div>
                        <a
                          [routerLink]="['/companies', company.CompanyName]"
                          class="text-blue-500 hover:text-blue-600"
                          >{{ company.CompanyName }}</a
                        >
                      </div>
                    </td>
                    @for (query of completedScreen.queries; track query) {
                      <td class="py-2 px-4">
                        <div class="text-center">
                          {{ company[query.key] }}
                        </div>
                      </td>
                    }
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      }
    </div>
  </div>
</div>
