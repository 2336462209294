<div class="max-w-screen-xl mx-auto">
  <div class="p-20 bg-blue-100">
    <div class="max-w-2xl mx-auto">
      <h2 class="text-center text-lg font-bold mb-6">
        {{ getHeaderTitle() }}
      </h2>

      @if (editingProfile && isCustomerInvestor()) {
        <div class="my-3 cursor-pointer" (click)="toggleEditingProfile()">
          back to (1/2)
        </div>
      }

      <form (ngSubmit)="onSubmit()" [formGroup]="signupForm" class="space-y-6">
        @if (!editingProfile) {
          <!-- Basic information fields -->
          <div class="space-y-4">
            <div>
              <label
                for="firstName"
                class="block mb-2 text-sm font-medium text-gray-700"
                >First Name*</label
              >
              <input
                type="text"
                id="firstName"
                formControlName="firstName"
                class="w-full px-3 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                [ngClass]="{ 'border-red-500': isFieldInvalid('firstName') }"
              />
              @if (isFieldInvalid("firstName")) {
                <p class="mt-1 text-sm text-red-500">First Name is required</p>
              }
            </div>

            <div>
              <label
                for="lastName"
                class="block mb-2 text-sm font-medium text-gray-700"
                >Last Name*</label
              >
              <input
                type="text"
                id="lastName"
                formControlName="lastName"
                class="w-full px-3 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                [ngClass]="{ 'border-red-500': isFieldInvalid('lastName') }"
              />
              @if (isFieldInvalid("lastName")) {
                <p class="mt-1 text-sm text-red-500">Last Name is required</p>
              }
            </div>
            <div>
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-700"
                >Email*</label
              >
              <input
                type="email"
                id="email"
                formControlName="email"
                class="w-full px-3 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                [ngClass]="{ 'border-red-500': isFieldInvalid('email') }"
              />
              @if (isFieldInvalid("email")) {
                <p class="mt-1 text-sm text-red-500">
                  @if (signupForm.get("email").errors?.required) {
                    Email is required
                  } @else if (signupForm.get("email").errors?.email) {
                    Please enter a valid email address
                  }
                </p>
              }
            </div>
            <div>
              <label
                for="password"
                class="block mb-2 text-sm font-medium text-gray-700"
                >Password*</label
              >
              <input
                type="password"
                id="password"
                formControlName="password"
                class="w-full px-3 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                [ngClass]="{ 'border-red-500': isFieldInvalid('password') }"
              />
              @if (isFieldInvalid("password")) {
                <p class="mt-1 text-sm text-red-500">
                  @if (signupForm.get("password").errors?.required) {
                    Password is required
                  } @else if (signupForm.get("password").errors?.minlength) {
                    Password must be at least 6 characters long
                  }
                </p>
              }
            </div>
            <div>
              <label
                for="confirmPassword"
                class="block mb-2 text-sm font-medium text-gray-700"
                >Confirm Password*</label
              >
              <input
                type="password"
                id="confirmPassword"
                formControlName="confirmPassword"
                class="w-full px-3 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                [ngClass]="{
                  'border-red-500': isFieldInvalid('confirmPassword')
                }"
              />
              @if (isFieldInvalid("confirmPassword")) {
                <p class="mt-1 text-sm text-red-500">
                  @if (signupForm.get("confirmPassword").errors?.required) {
                    Confirm Password is required
                  } @else if (signupForm.errors?.mismatch) {
                    Passwords do not match
                  }
                </p>
              }
            </div>
            <div>
              <label
                for="customerType"
                class="block mb-2 text-sm font-medium text-gray-700"
                >Role*</label
              >
              <select
                formControlName="customerType"
                class="w-full px-3 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                [ngClass]="{ 'border-red-500': isFieldInvalid('customerType') }"
              >
                @for (option of customerTypes; track option) {
                  <option [value]="option">{{ option }}</option>
                }
              </select>
              @if (isFieldInvalid("customerType")) {
                <p class="mt-1 text-sm text-red-500">Role is required</p>
              }
            </div>

            <div>
              <label
                for="companyName"
                class="block mb-2 text-sm font-medium text-gray-700"
                >Company Name</label
              >
              <input
                type="text"
                id="companyName"
                formControlName="companyName"
                class="w-full px-3 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label
                for="city"
                class="block mb-2 text-sm font-medium text-gray-700"
                >City</label
              >
              <input
                type="text"
                id="city"
                formControlName="city"
                class="w-full px-3 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>

          <div class="mt-6">
            <h4 class="text-lg font-bold mb-2">
              Subscribe to the following newsletters
            </h4>
            <ul class="divide-y divide-gray-200">
              @for (newsletter of allNewsletters; track newsletter.id) {
                <li class="py-3 flex justify-between items-center">
                  <span>{{ newsletter.name }}</span>
                  <div class="flex items-center">
                    <input
                      type="checkbox"
                      [id]="'newsletter-' + newsletter.id"
                      class="form-checkbox h-5 w-5 text-blue-600"
                      [checked]="newsletter.subscribed"
                      [disabled]="loading"
                      (change)="addToAutoSubscribeList(newsletter.id)"
                    />
                    <label
                      [for]="'newsletter-' + newsletter.id"
                      class="ml-2 text-sm text-gray-700"
                    ></label>
                  </div>
                </li>
              }
            </ul>
          </div>

          @if (!isCustomerInvestor()) {
            <div class="text-center mt-6">
              <button
                type="submit"
                [disabled]="loading || signupForm.invalid"
                class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
              >
                Register
              </button>
            </div>
            <div class="mt-4 text-center">
              @if (loading) {
                <p class="text-blue-600">Your request is being processed...</p>
              }
              @if (success) {
                <p class="text-green-600">
                  Thank you! We have sent an email to your address. Please click
                  on the link in that email to confirm your registration. You
                  will then be able to login.
                </p>
              }
              @if (showAlert) {
                <p class="text-red-600">
                  This email may already exist.
                  <a [routerLink]="['/requestnewpassword']" class="underline"
                    >Forgot your password?</a
                  >
                </p>
              }
              @if (errorMessage) {
                <p class="text-red-600">{{ errorMessage }}</p>
              }
            </div>
          } @else {
            <div class="text-center mt-6">
              <button
                type="button"
                (click)="toggleEditingProfile()"
                [disabled]="!isBasicInfoValid()"
                class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
              >
                Next: Complete Profile
              </button>
            </div>
          }
        }

        @if (editingProfile && isCustomerInvestor()) {
          <div formGroupName="profileData" class="space-y-4">
            @for (field of investorProfileFields; track field) {
              @if (field !== "PROFIL_INVESTORENTYP") {
                <div>
                  <label
                    [for]="field"
                    class="block mb-2 text-sm font-medium text-gray-700"
                  >
                    {{ field | profileAttributeTranslator }}*:
                  </label>
                  <select
                    [id]="field"
                    [formControlName]="field"
                    class="w-full px-3 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                    [ngClass]="{
                      'border-red-500': isProfileFieldInvalid(field)
                    }"
                  >
                    @for (option of getEnumOptions(field); track option) {
                      <option [value]="option">{{ option }}</option>
                    }
                  </select>
                  @if (isProfileFieldInvalid(field)) {
                    <p class="mt-1 text-sm text-red-500">
                      {{ field | profileAttributeTranslator }} is required
                    </p>
                  }
                </div>
              }
            }
          </div>

          <div class="mt-6">
            <h4 class="text-lg font-bold mb-2">
              Subscribe to the following newsletters
            </h4>
            <ul class="divide-y divide-gray-200">
              @for (newsletter of allNewsletters; track newsletter.id) {
                <li class="py-3 flex justify-between items-center">
                  <span>{{ newsletter.name }}</span>
                  <div class="flex items-center">
                    <input
                      type="checkbox"
                      [id]="'newsletter-' + newsletter.id"
                      class="form-checkbox h-5 w-5 text-blue-600"
                      [checked]="newsletter.subscribed"
                      [disabled]="loading"
                      (change)="addToAutoSubscribeList(newsletter.id)"
                    />
                    <label
                      [for]="'newsletter-' + newsletter.id"
                      class="ml-2 text-sm text-gray-700"
                    ></label>
                  </div>
                </li>
              }
            </ul>
          </div>

          <div class="text-center mt-6">
            <button
              type="submit"
              [disabled]="loading || signupForm.invalid"
              class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
            >
              Register
            </button>
          </div>
          <div class="mt-4 text-center">
            @if (loading) {
              <p class="text-blue-600">Your request is being processed...</p>
            }
            @if (success) {
              <p class="text-green-600">
                Thank you! We have sent an email to your address. Please click
                on the link in that email to confirm your registration. You will
                then be able to login.
              </p>
            }
            @if (showAlert) {
              <p class="text-red-600">
                This email may already exist.
                <a [routerLink]="['/requestnewpassword']" class="underline"
                  >Forgot your password?</a
                >
              </p>
            }
            @if (errorMessage) {
              <p class="text-red-600">{{ errorMessage }}</p>
            }
          </div>
        }
      </form>
    </div>
  </div>
</div>
