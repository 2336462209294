<div class="container mx-auto px-16">
  <div class="flex flex-col space-y-8 pt-12">
    <div class="w-full md:w-5/6">
      <p class="italic text-gray-600 mb-4">
        This document contains current disclosures and disclaimers of mwb
        research AG in English and German.
      </p>
      <p class="italic text-gray-600 mb-8">
        Dieses Dokument beinhaltet Interessenkonflikte und Haftungsausschlüsse
        der mwb research AG auf Englisch und Deutsch.
      </p>
      <h3 class="text-lg font-bold mb-4">Disclaimer</h3>
      <p class="text-gray-700 mb-4 leading-relaxed">
        <strong>1. General Information/Liabilities: </strong>mwb research AG's
        research reports have been produced for the information purposes of
        institutional investors only, and are not in any way a personal
        recommendation, offer or solicitation to buy or sell the financial
        instruments mentioned. The documents are confidential and are made
        available by mwb research AG, exclusively to selected recipients in DE,
        GB, FR, CH, US, UK, Scandinavia, and Benelux or, in individual cases,
        also in other countries. A distribution to private investors in the
        sense of the German Securities Trading Act (WpHG) is excluded. It is not
        allowed to pass the research report on to persons other than the
        intended recipient without the permission of mwb research AG.
        Reproduction of this document, in whole or in part, is not permitted
        without prior permission mwb research AG. All rights reserved. Under no
        circumstances shall mwb research AG, or any of its employees involved in
        the preparation, have any liability for possible errors or
        incompleteness of the information included in these research reports –
        neither in relation to indirect or direct nor consequential damages.
        Liability for damages arising either directly or as a consequence of the
        use of information, opinions and estimates is also excluded. Past
        performance of a financial instrument is not necessarily indicative of
        future performance.
      </p>
      <p class="text-gray-700 mb-4 leading-relaxed">
        <strong>2. Responsibilities: </strong>Research reports are prepared by
        the research analyst named on the front page of the PDF (the
        ʺProducerʺ). The Producer is solely responsible for the views and
        estimates expressed in reports. The reports have been prepared
        independently. The content of the research reports were not influenced
        by the issuer of the analyzed financial instrument at any time. It may
        be possible that parts of the research reports were handed out to the
        issuer for information purposes prior to the publication without any
        major amendments being made thereafter.
      </p>
      <p class="text-gray-700 mb-4 leading-relaxed">
        <strong>3. Organizational Requirements: </strong>mwb research AG took
        internal organizational and regulative precautions to avoid or
        accordingly disclose possible conflicts of interest in connection with
        the preparation and distribution of research reports. All members of mwb
        research involved in the preparation of the research reports are subject
        to internal compliance regulations. No part of the Producer's
        compensation is directly or indirectly related to the preparation of
        these financial analyses. In case a research analyst or a closely
        related person is confronted with a conflict of interest, the research
        analyst is restricted from covering this company.
      </p>
      <p class="text-gray-700 mb-4 leading-relaxed">
        <strong
          >4. Information Concerning the Methods of Valuation/Update:
        </strong>
        The determination of the fair value per share, i.e. the price target,
        and the resultant rating is done on the basis of the adjusted free cash
        flow (adj. FCF) method and on the basis of the discounted cash flow –
        DCF model. Furthermore, a peer group comparison can be made. For
        business models that typically cannot be valued by the aforementioned
        methods, for example real estate holding or asset management companies,
        other methods may be applied. The adj. FCF method is based on the
        assumption that investors purchase assets only at a price (enterprise
        value) at which the operating cash flow return after taxes on this
        investment exceeds their opportunity costs in the form of a hurdle rate.
        The hurdle rate might be adjusted for ESG criteria. The operating cash
        flow is calculated as EBITDA less maintenance capex and taxes. Within
        the framework of the DCF approach, the future free cash flows are
        calculated initially on the basis of a fictitious capital structure of
        100% equity, i.e. interest and repayments on debt capital are not
        factored in initially. The adjustment towards the actual capital
        structure is done by discounting the calculated free cash flows with the
        weighted average cost of capital (WACC), which takes into account both
        the cost of equity capital and the cost of debt. After discounting, the
        calculated total enterprise value is reduced by the interest-bearing
        debt capital in order to arrive at the equity value.
      </p>
      <p class="text-gray-700 mb-4 leading-relaxed">
        <b>ENG:</b> mwb research AG uses the following three-step rating system
        for the analyzed companies:
      </p>
      <ul class="list-disc pl-6 mb-4 space-y-2 text-gray-700">
        <li>
          <strong>Spec. Buy: </strong>Speculative (Spec.) BUY: Sustainable
          upside potential of more than 25% within 12 months, above average
          risk.
        </li>
        <li>
          <strong>Buy: </strong>Sustainable upside potential of more than 10%
          within 12 months.
        </li>
        <li>
          <strong>Sell: </strong>Sustainable downside potential of more than 10%
          within 12 months.
        </li>
        <li>
          <strong>Hold: </strong>Upside/downside potential is limited below 10%.
          No immediate catalyst visible.
        </li>
        <li>
          NB: The ratings of mwb research AG are not based on a performance that
          is expected to be "relative" to the market.
        </li>
      </ul>
      <p class="text-gray-700 mb-4 leading-relaxed">
        The decision on the choice of the financial instruments analyzed are
        solely made by mwb research AG. The opinions and estimates in the
        research reports are subject to change without notice. It is within the
        discretion of mwb research AG whether and when it publishes an update to
        research reports, but in general updates are created on a regular basis,
        after 6 months at the latest. A sensitivity analysis is included and
        published in company's initial studies.
      </p>
      <p class="text-gray-700 mb-4 leading-relaxed">
        <b>DE:</b> mwb research AG nutzt das folgende dreistufige
        Bewertungssystem für die analysierten Unternehmen:
      </p>
      <ul class="list-disc pl-6 mb-4 space-y-2 text-gray-700">
        <li>
          <strong>Sekulatives Kaufen: </strong>Nachhaltiges
          Kurssteigerungspotenzial von mehr als 25% innerhalb von 12 Monaten;
          überdurchschnittliches Risiko.
        </li>
        <li>
          <strong>Kaufen: </strong> Nachhaltiges Kurssteigerungspotenzial von
          mehr als 10% innerhalb von 12 Monaten.
        </li>
        <li>
          <strong>Verkaufen: </strong>Nachhaltiges Abwärtspotential von mehr als
          10% innerhalb von 12 Monaten.
        </li>
        <li>
          <strong>Halten: </strong>Das Aufwärts-/Abwärtspotential ist begrenzt.
          Kein unmittelbarer Katalysator sichtbar.
        </li>
        <li>
          NB: Die Ratings der mwb research AG basieren nicht auf einer Leistung,
          die "relativ" zum Markt sein wird.
        </li>
      </ul>
      <p class="text-gray-700 mb-4 leading-relaxed">
        The decision on the choice of the financial instruments analyzed are
        solely made by mwb research AG. The opinions and estimates in the
        research reports are subject to change without notice. It is within the
        discretion of mwb research AG whether and when it publishes an update to
        research reports, but in general updates are created on a regular basis,
        after 6 months at the latest. A sensitivity analysis is included and
        published in company's initial studies.
      </p>
      <p class="text-gray-700 mb-4 leading-relaxed">
        <strong>5. Risk information</strong>
      </p>
      <ul class="list-disc pl-6 mb-4 space-y-2 text-gray-700">
        <li>
          Stock market investments and investments in companies (shares) are
          always speculative and involve the risk of total loss.
        </li>
        <li>
          This is particularly true with respect to investments in companies
          that are not established and/or are small and do not have established
          operations and corporate assets.
        </li>
        <li>
          Share prices may fluctuate significantly. This applies in particular
          to shares that have only low liquidity (market breadth). Here, even
          small orders can have a significant impact on the share price.
        </li>
        <li>
          In the case of shares in narrow markets, it may also be the case that
          there is no actual trading at all or only very limited trading and
          that published prices are not based on actual trading but have only
          been provided by a stockbroker.
        </li>
        <li>
          In such markets, a shareholder cannot expect to find a buyer for his
          shares at all and/or at reasonable prices.
        </li>
        <li>
          In such narrow markets, there is a very high possibility of
          manipulation of prices and the prices in such markets often also
          fluctuate significantly.
        </li>
        <li>
          An investment in securities with low liquidity, as well as low market
          capitalization is therefore highly speculative and represents a very
          high risk.
        </li>
        <li>
          In the case of unlisted shares and securities, there is no regulated
          market and sale is not possible or only possible on an individual
          basis.
        </li>
      </ul>
      <p class="text-gray-700 mb-4 leading-relaxed">
        <strong>6. Major Sources of Information: </strong>Part of the
        information required for research reports is made available by the
        issuer of the financial instrument. Furthermore, reports are based on
        publicly available sources (such as, for example, Bloomberg, Reuters and
        the relevant daily press) believed to be reliable. mwb research has
        checked the information for plausibility but not for accuracy or
        completeness.
      </p>
      <p class="text-gray-700 mb-4 leading-relaxed">
        <strong>7. Competent Supervisory Authority: </strong> mwb research AG is
        registered at the BaFin – German Federal Financial Supervisory Authority
        (Bundesanstalt für Finanzdienstleistungsaufsicht), Graurheindorfer
        Straße 108, 53117 Bonn and Marie-Curie-Straße 24 – 28, 60439 Frankfurt
        a.M. Documents are distributed in the UK under a MiFID EEA branch
        passport and in compliance with the applicable FCA requirements.
      </p>
      <p class="text-gray-700 mb-4 leading-relaxed">
        <strong
          >8. Specific Comments for Recipients Outside of Germany:
        </strong>
        Research reports are subject to the law of the Federal Republic of
        Germany. The distribution of information to other states in particular
        to the USA, Canada, Australia and Japan may be restricted or prohibited
        by the laws applicable within this state.
      </p>
      <p class="text-gray-700 mb-4 leading-relaxed">
        <strong>9. Miscellaneous</strong> According to Article 4(1) No. i of the
        delegated regulation 2016/958 supplementing regulation 596/2014 of the
        European Parliament, further information regarding investment
        recommendations of the last 12 months are published under:
        https://www.research-hub.de
      </p>
    </div>
    <br />
    <div class="w-full md:w-5/6">
      <h3 class="text-lg font-bold mb-4">Haftungsausschluss</h3>
      <p class="text-gray-700 mb-4 leading-relaxed">
        <strong>1. Allgemeine Informationen/Haftung: </strong>Aktienanalysen der
        mwb research AG („Studien") werden ausschließlich zu Informationszwecken
        für institutionelle Investoren erstellt und stellen in keiner Weise eine
        persönliche Empfehlung, ein Angebot oder eine Aufforderung zum Kauf oder
        Verkauf der genannten Finanzinstrumente dar. Die Studien sind
        vertraulich und werden von der mwb research AG ausschließlich
        ausgewählten Empfängern in DE, GB, FR, CH, US, UK, Skandinavien und
        Benelux oder in Einzelfällen auch in anderen Ländern zur Verfügung
        gestellt. Eine Verteilung an Privatanleger im Sinne des
        Wertpapierhandelsgesetzes (WpHG) ist ausgeschlossen. Die Weitergabe der
        Studien an andere Personen als den vorgesehenen Empfänger ist ohne die
        Zustimmung der mwb research AG nicht gestattet. Die Vervielfältigung der
        Studien, auch auszugsweise, ist ohne vorherige Genehmigung der mwb
        research AG nicht gestattet. Alle Rechte vorbehalten. In keinem Fall
        übernimmt die mwb research AG oder einer ihrer Mitarbeiter, die an der
        Erstellung beteiligt waren, eine Haftung für eventuelle Fehler oder
        Unvollständigkeiten der in den Studien enthaltenen Informationen - weder
        für mittelbare oder unmittelbare Schäden noch für Folgeschäden. Eine
        Haftung für Schäden, die entweder direkt oder als Folge der Verwendung
        von Informationen, Meinungen und Schätzungen entstehen, ist ebenfalls
        ausgeschlossen. Die Wertentwicklung eines Finanzinstruments in der
        Vergangenheit ist nicht notwendigerweise ein Indikator für die
        zukünftige Wertentwicklung.
      </p>
      <p class="text-gray-700 mb-4 leading-relaxed">
        <strong>2. Verantwortlichkeiten: </strong>Studien wurden von dem auf der
        Titelseite des PDF-Dokuments genannten Rechercheanalysten (der
        Produzent) erstellt. Der Produzent ist allein verantwortlich für die in
        den Studien geäußerten Ansichten und Einschätzungen. Studien wurde
        unabhängig erstellt. Inhalte der Studien wurden zu keinem Zeitpunkt
        durch den Emittenten des analysierten Finanzinstruments beeinflusst. Es
        ist möglich, dass Teile der Studien dem Emittenten vor der
        Veröffentlichung zu Informationszwecken ausgehändigt wurden, ohne dass
        danach wesentliche Änderungen vorgenommen wurden.
      </p>
      <p class="text-gray-700 mb-4 leading-relaxed">
        <strong>3. Organisatorische Vorkehrungen: </strong>Die mwb research AG
        hat interne organisatorische und regulative Vorkehrungen getroffen, um
        mögliche Interessenkonflikte im Zusammenhang mit der Erstellung und
        Verteilung der Studien zu vermeiden oder entsprechend offen zu legen.
        Alle Mitglieder der mwb research AG, die an der Erstellung der Studien
        beteiligt sind, unterliegen den internen Compliance-Vorschriften. Kein
        Teil der Vergütung der Produzenten steht in direktem oder indirektem
        Zusammenhang mit der Erstellung der Studien. Für den Fall, dass ein
        Research-Analyst oder eine nahestehende Person in einen
        Interessenkonflikt gerät, ist der Research-Analyst von der
        Berichterstattung über dieses Unternehmen ausgeschlossen.
      </p>
      <p class="text-gray-700 mb-4 leading-relaxed">
        <strong
          >4. Informationen bezüglich der Bewertungsmethoden/Aktualisierung:
        </strong>
        Die Bestimmung des fairen Wertes pro Aktie, d.h. des Kursziels, und des
        daraus resultierenden Ratings erfolgt auf der Grundlage der Methode des
        bereinigten freien Cashflows (adj. FCF) und auf der Grundlage des
        Discounted-Cashflow-(DCF)-Modells. Darüber hinaus wird ein
        Peer-Group-Vergleich durchgeführt. Bei Geschäftsmodellen, die
        typischerweise nicht mit den oben genannten Methoden bewertet werden
        können, z. B. Immobilienholding- oder
        Vermögensverwaltungsgesellschaften, können andere Methoden angewandt
        werden. Die adj. FCF-Methode basiert auf der Annahme, dass Investoren
        Vermögenswerte nur zu einem Preis (Unternehmenswert) erwerben, bei dem
        die operative Cashflow-Rendite nach Steuern auf diese Investition ihre
        Opportunitätskosten in Form einer Hurdle Rate übersteigt. Die Hurdle
        Rate kann für ESG-Kriterien angepasst werden. Der Betriebs-Cashflow wird
        als EBITDA abzüglich Erhaltungsinvestitionen und Steuern berechnet. Im
        Rahmen des DCF-Ansatzes werden die zukünftigen freien Cashflows zunächst
        auf der Basis einer fiktiven Kapitalstruktur von 100% Eigenkapital
        berechnet, d.h. Zinsen und Tilgungen auf Fremdkapital werden zunächst
        nicht berücksichtigt. Die Anpassung an die tatsächliche Kapitalstruktur
        erfolgt durch Diskontierung der berechneten freien Cashflows mit dem
        gewichteten durchschnittlichen Kapitalkostensatz (WACC), der sowohl die
        Kosten für Eigenkapital als auch die Kosten für Fremdkapital
        berücksichtigt. Nach der Diskontierung wird der berechnete
        Gesamtunternehmenswert um das verzinsliche Fremdkapital verringert, um
        den Eigenkapitalwert zu erhalten.
      </p>
      <p class="text-gray-700 mb-4 leading-relaxed">
        Die mwb research AG verwendet für die analysierten Unternehmen das
        folgende dreistufige Ratingsystem:
      </p>
      <ul class="list-disc pl-6 mb-4 space-y-2 text-gray-700">
        <li>
          <strong>Kaufen: </strong>Nachhaltiges Kurssteigerungspotenzial von
          mehr als 10% innerhalb von 12 Monaten
        </li>
        <li>
          <strong>Verkaufen:</strong>Nachhaltiges Abwärtspotential von mehr als
          10% innerhalb von 12 Monaten.
        </li>
        <li>
          <strong>Halten:</strong>Das Aufwärts-/Abwärtspotential ist unter 10%.
          Kein unmittelbarer Katalysator sichtbar.
        </li>
        <li>
          NB: Die Ratings der mwb research AG basieren nicht auf einer
          Performance, die "relativ" zum Markt sein wird.
        </li>
      </ul>
      <p class="text-gray-700 mb-4 leading-relaxed">
        Die Entscheidung über die Wahl der in den Studien analysierten
        Finanzinstrumente wurde ausschließlich von der mwb research AG
        getroffen. Die Meinungen und Einschätzungen in den Studien können ohne
        vorherige Ankündigung geändert werden. Es liegt im Ermessen der mwb
        research AG, ob und wann sie eine Aktualisierung einer Studie
        veröffentlicht, aber im Allgemeinen werden Aktualisierungen regelmäßig,
        spätestens nach 6 Monaten, erstellt. Eine Sensitivitätsanalyse ist in
        den ersten Studien des Unternehmens enthalten und wird veröffentlicht.
      </p>
      <p class="text-gray-700 mb-4 leading-relaxed">
        <strong>5. Risikohinweise</strong>
      </p>
      <ul class="list-disc pl-6 mb-4 space-y-2 text-gray-700">
        <li>
          Börsenanlagen und Anlagen in Unternehmen (Aktien) sind immer
          spekulativ und beinhalten das Risiko des Totalverlustes.
        </li>
        <li>
          Dies gilt insbesondere in Bezug auf Anlagen in Unternehmen, die nicht
          etabliert sind und/oder klein sind und keinen etablierten
          Geschäftsbetrieb und Firmenvermögen haben.
        </li>
        <li>
          Aktienkurse können erheblich schwanken. Dies gilt insbesondere bei
          Aktien, die nur über eine geringe Liquidität (Marktbreite) verfügen.
          Hier können auch nur geringe Aufträge erheblichen Einfluss auf den
          Aktienkurs haben.
        </li>
        <li>
          Bei Aktien in engen Märkten kann es auch dazu kommen, dass dort
          überhaupt kein oder nur sehr geringer tatsächlicher Handel besteht und
          veröffentlichte Kurse nicht auf einem tatsächlichen Handel beruhen,
          sondern nur von einem Börsenmakler gestellt worden sind.
        </li>
        <li>
          Ein Aktionär kann bei solchen Märkten nicht damit rechnen, dass er für
          seine Aktien einen Käufer überhaupt und/oder zu angemessenen Preisen
          findet.
        </li>
        <li>
          In solchen engen Märkten besteht eine sehr hohe Möglichkeit zur
          Manipulation der Kurse und der Preise in solchen Märkten kommt es oft
          auch zu erheblichen Preisschwankungen.
        </li>
        <li>
          Eine Investition in Wertpapiere mit geringer Liquidität, sowie
          niedriger Börsenkapitalisierung ist daher höchst spekulativ und stellt
          ein sehr hohes Risiko dar.
        </li>
        <li>
          Bei nicht börsennotierten Aktien und Papieren besteht kein geregelter
          Markt und ein Verkauf ist nicht oder nur auf individueller Basis
          möglich.
        </li>
      </ul>
      <p class="text-gray-700 mb-4 leading-relaxed">
        <strong>6. Wichtigste Informationsquellen: </strong>Ein Teil der für die
        Studien erforderlichen Informationen werden von Emittenten der
        Finanzinstrumente zur Verfügung gestellt. Darüber hinaus stützten sich
        die Studien auf öffentlich zugängliche Quellen (wie z.B. Bloomberg,
        Reuters und die einschlägige Tagespresse), die als zuverlässig erachtet
        werden. Die mwb research AG prüft die Informationen auf Plausibilität,
        aber nicht auf Richtigkeit und Vollständigkeit.
      </p>
      <p class="text-gray-700 mb-4 leading-relaxed">
        <strong>7. Zuständige Aufsichtsbehörde: </strong>Die mwb research AG ist
        bei der der BaFin registriert - Bundesanstalt für
        Finanzdienstleistungsaufsicht, Graurheindorfer Straße 108, 53117 Bonn
        und Marie-Curie-Straße 24 - 28, 60439 Frankfurt a.M. Studien werden im
        Vereinigten Königreich mit einem MiFID Zweigstellenpass für den EWR und
        in Übereinstimmung mit den geltenden Anforderungen der FCA verteilt.
      </p>
      <p class="text-gray-700 mb-4 leading-relaxed">
        <strong
          >8. Besondere Anmerkungen für Empfänger außerhalb Deutschlands:
        </strong>
        Die Studien unterliegen dem Recht der Bundesrepublik Deutschland. Die
        Verbreitung der Informationen in andere Staaten, insbesondere in die
        USA, Kanada, Australien und Japan, kann durch die in diesem Staat
        geltenden Gesetze eingeschränkt oder verboten sein.
      </p>
      <p class="text-gray-700 mb-4 leading-relaxed">
        <strong>9. Sonstiges: </strong>Gemäß Artikel 4 Absatz 1 Nr. i der
        delegierten Verordnung 2016/958 zur Ergänzung der Verordnung 596/2014
        des Europäischen Parlaments, weitere Informationen zu Anlageempfehlungen
        der letzten 12 Monate werden kostenfrei veröffentlicht unter:
        https://www.research-hub.de.
      </p>
    </div>
    <br />
    <div class="w-full md:w-5/6">
      <h3 class="text-lg font-bold mb-4">Conflicts of interest</h3>
      <p class="text-gray-700 mb-4 leading-relaxed">
        Disclosures regarding research publications of mwb research AG pursuant
        to section 85 of the German Securities Trading Act (WpHG) and
        distributed in the UK under an EEA branch passport, subject to the FCA
        requirements on research recommendation disclosures. It is essential
        that any research recommendation is fairly presented and discloses
        interests of indicates relevant conflicts of interest. Pursuant to
        section 85 of the German Securities Trading Act (WpHG) a research report
        has to point out possible conflicts of interest in connection with the
        analyzed company. Further to this, under the FCA's rules on research
        recommendations, any conflicts of interest in connection with the
        recommendation must be disclosed. A conflict of interest is presumed to
        exist in particular if mwb research AG
      </p>
      <ol class="list-decimal pl-6 mb-4 space-y-2 text-gray-700">
        <li>
          or its affiliate(s) (either in its own right or as part of a
          consortium) within the past twelve months, acquired the financial
          instruments of the analyzed company,
        </li>
        <li>
          has entered into an agreement on the production of the research report
          with the analyzed company,
        </li>
        <li>
          or its affiliate(s) has, within the past twelve months, been party to
          an agreement on the provision of investment banking services with the
          analyzed company or have received services or a promise of services
          under the term of such an agreement,
        </li>
        <li>
          or its affiliate(s) holds a) 5% or more of the share capital of the
          analyzed company, or b) the analyzed company holds 5% or more of the
          share capital of mwb research AG or its affiliate(s),
        </li>
        <li>
          or its affiliate(s) holds a net long (a) or a net short (b) position
          of 0.5% of the outstanding share capital of the analyzed company or
          derivatives thereof,
        </li>
        <li>
          or its affiliate(s) is a market maker or liquidity provider in the
          financial instruments of the issuer,
        </li>
        <li>
          or the analyst has any other significant financial interests relating
          to the analyzed company such as, for example, exercising mandates in
          the interest of the analyzed company or a significant conflict of
          interest with respect to the issuer,
        </li>
        <li>
          The research report has been made available to the company prior to
          its publication. Thereafter, only factual changes have been made to
          the report.
        </li>
      </ol>
      <p class="text-gray-700 leading-relaxed">
        Conflicts of interest that existed at the time of publication of a study
        are disclosed in the archived PDF documents under "Research" on the
        ResearchHub. The current conflicts of interest are shown in the table at
        the bottom of this document.
      </p>
    </div>
    <br />
    <div class="w-full md:w-5/6">
      <h3 class="text-lg font-bold mb-4">Interessenkonflikte</h3>
      <p class="text-gray-700 mb-4 leading-relaxed">
        Gemäß § 85 des Wertpapierhandelsgesetzes (WpHG) muss ein Analysebericht
        auf mögliche Interessenkonflikte im Zusammenhang mit dem analysierten
        Unternehmen hinweisen. Ein Interessenkonflikt wird insbesondere dann
        vermutet, wenn die mwb research AG:
      </p>
      <ol class="list-decimal pl-6 mb-4 space-y-2 text-gray-700">
        <li>
          oder ihre Tochtergesellschaft(en) (entweder als eigene Einheit oder
          als Teil eines Konsortiums) innerhalb der letzten zwölf Monate die
          Finanzinstrumente des analysierten Unternehmens erworben hat,
        </li>
        <li>
          mit dem analysierten Unternehmen eine Vereinbarung über die Erstellung
          des Rechercheberichts getroffen hat,
        </li>
        <li>
          oder ihre Tochtergesellschaft(en) innerhalb der letzten zwölf Monate
          mit dem analysierten Unternehmen eine Vereinbarung über die Erbringung
          von Investment-Banking-Dienstleistungen getroffen oder
          Dienstleistungen oder ein Leistungsversprechen im Rahmen der Laufzeit
          einer solchen Vereinbarung erhalten haben,
        </li>
        <li>
          oder deren Tochtergesellschaft(en) a) 5% oder mehr des Aktienkapitals
          des analysierten Unternehmens hält oder b) das analysierte Unternehmen
          5% oder mehr des Aktienkapitals der mwb research AG oder deren
          Tochtergesellschaft(en) hält,
        </li>
        <li>
          oder seine Tochtergesellschaft(en) eine Netto-Long (a)- oder eine
          Netto-Short (b)-Position von mindestens 0,5% des ausstehenden
          Aktienkapitals des analysierten Unternehmens oder seiner Derivate
          hält,
        </li>
        <li>
          oder ihre verbundene(n) Gesellschaft(en) ein Market Maker oder
          Liquiditätsanbieter in den Finanzinstrumenten des Emittenten ist,
        </li>
        <li>
          oder der Analyst andere bedeutende finanzielle Interessen in Bezug auf
          das analysierte Unternehmen hat, wie z.B. die Ausübung von Mandaten im
          Interesse des analysierten Unternehmens oder einen bedeutenden
          Interessenkonflikt in Bezug auf den Emittenten,
        </li>
        <li>
          Der Recherchebericht wurde dem Unternehmen vor seiner Veröffentlichung
          zur Verfügung gestellt. Danach wurden nur noch faktische Änderungen an
          dem Bericht vorgenommen.
        </li>
      </ol>
      <p class="text-gray-700 leading-relaxed">
        Interessenkonflikte, die zum Zeitpunkt der Veröffentlichung einer Studie
        bestanden, sind in den archivierten PDF-Dokumenten unter „Research" auf
        dem ResearchHub offengelegt. Aktuell werden die in der Tabelle unten
        dargestellten Interessenkonflikte offengelegt.
      </p>
    </div>
    <br />
    <div class="w-full overflow-x-auto">
      <table class="table-auto border-collapse border border-gray-400">
        <thead class="bg-gray-100 text-black">
          <tr>
            <th class="border border-gray-400 px-4 py-2">
              Company / Unternehmen
            </th>
            <th class="border border-gray-400 px-4 py-2">Ticker</th>
            <th class="border border-gray-400 px-4 py-2">Bloomberg</th>
            <th class="border border-gray-400 px-4 py-2">
              Disclosures / Interessenkonflikte
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          @for (company of companies(); track company) {
            <tr class="">
              <td class="border border-gray-400 px-4 py-2">
                {{ company.name || "" }}
              </td>
              <td class="border border-gray-400 px-4 py-2">
                {{ company.ticker || "" }}
              </td>
              <td class="border border-gray-400 px-4 py-2">
                {{ company.bloomberg || "" }}
              </td>
              <td class="border border-gray-400 px-4 py-2">
                {{ company.disclosures || "" }}
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</div>
