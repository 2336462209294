import { Component, inject, Input } from "@angular/core";

import { DownloadPdfAnalyticsService } from "~features/pdfAnalytics/download-pdf-analytics.service";

@Component({
  selector: "app-button-download-pdf",
  standalone: true,
  imports: [],
  templateUrl: "./button-download-pdf.component.html",
  styleUrl: "./button-download-pdf.component.scss",
})
export class ButtonDownloadPdfComponent {
  pdfAnalycitsService = inject(DownloadPdfAnalyticsService);
  @Input() pdf;
  @Input() buttonClasses = "";

  updatePdfCounter(pdfURL) {
    this.pdfAnalycitsService.addPDFCounter(pdfURL);
  }
}
