import { JsonPipe, NgClass } from "@angular/common";
import {
  Component,
  computed,
  effect,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
  Signal,
  ViewChild,
  WritableSignal,
} from "@angular/core";

import { ChartComponent } from "~features/charts/chart/chart.component";
import { Charting } from "~features/charts/charting.util";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { ExcelService } from "~features/company/excel.service";
import { DataServiceService } from "~features/data-service.service";

@Component({
  selector: "app-template-chart",
  templateUrl: "./template-chart.component.html",
  styleUrls: ["./template-chart.component.scss"],
  standalone: true,
  imports: [ChartComponent, JsonPipe, NgClass],
})
export class TemplateChartComponent {
  @ViewChild("chartWrapperTemplateCharts") chartWrapper: ElementRef;

  @Input() companyData: Signal<any>;
  @Input() baseyear: Signal<number> = signal(Number(new Date().getFullYear()));
  @Input() config: Signal<any>;
  @Input() decimals = 0;
  @Input() lang = "eng";
  @Input() noSliders = false;
  @Output() tileEvent: EventEmitter<any> = new EventEmitter();
  public index = signal(0);
  public chartTemplate: WritableSignal<any> = signal({});
  // Compute the current chart template name based on config and index
  public chartTemplateName = computed(() => {
    const config = this.config();
    const currentIndex = this.index();
    return config.content[currentIndex];
  });
  private excelService = inject(ExcelService);
  private chartingUtil = inject(Charting);
  private dataService = inject(DataServiceService);
  // Fecthing chart template
  getChartTemplate = effect(
    () => {
      this.chartTemplate.set(null);
      // console.log("INIT CHART IN EFFECT TEMPLATE CHART COMPONENT");
      const templateName = this.chartTemplateName();

      if (typeof templateName === "string") {
        this.dataService
          .getChartingTemplate(templateName)
          .subscribe((res: any) => {
            this.chartTemplate.set(res);
          });
      }
    },
    { allowSignalWrites: true }
  );
  private companyTickerService = inject(CompanyTickerService);
  private currentCompanyTicker = this.companyTickerService.getCompanyTicker();
  // Compute charting data based on company data and chart template
  public chartingData = computed(() => {
    const template = this.chartTemplate();
    const companyNumbers = this.companyData();

    if (companyNumbers && template && template.template) {
      return this.chartingUtil.createChartingData(
        template,
        companyNumbers,
        template.template,
        this.decimals,
        this.currentCompanyTicker(),
        template.settings.type
      );
    }
    return null;
  });
  chart = computed(() => {
    if (this.chartingData() !== null) {
      this.emitHeadlineChange(this.chartingData());
    }

    return this.parseChartData(this.chartingData());
  });

  parseChartData(rawData: any): any {
    if (!rawData) return null;
    let data: any[] = [];
    let layout: any = {
      showlegend: true,
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      barmode: "relative",
      hoverinfo: "all",
      hoverlabel: { namelength: 2 },
      // textinfo: "label+percent",
      displayModeBar: false,
      xaxis: {
        tickangle: 0,
        type: "category",
        showgrid: false,
        zeroline: false,
        tickfont: {
          size: 16,
          color: "#e2e8f0",
        },
        fixedrange: true,
      },
      yaxis: {
        showgrid: false,
        zeroline: false,
        visible: true,
        tickfont: {
          size: 16,
          color: "#e2e8f0",
        },
        // tickformat: ',d',
        y2: {
          showgrid: false,
          zeroline: false,
          visible: true,
          tickfont: {
            size: 16,
            color: "#e2e8f0",
          },
          fixedrange: true,
          // tickformat: ',d'
        },
        fixedrange: true,
      },
      legend: {
        orientation: "h",
        yanchor: "bottom",
        y: -0.2,
        xanchor: "left",
        x: 0,
        font: {
          size: 16,
          color: "#e2e8f0",
        },
      },

      margin: {
        t: 30,
        b: 30,
        l: 50,
        r: 50,
      },
      bargap: 0.4,
    };
    const config = {
      displayModeBar: false,
      responsive: true,
    };

    // Check if we have mixed types (bar and line)
    const types = new Set(rawData.data.map((item) => item.type));
    if (types.has("GROUPEDBAR") && types.has("LINE")) {
      data = this.styleMixedChart(this.parseMixedChart(rawData.data));
      layout = this.styleMixedChartLayout(data, layout);
    } else {
      // Handle other chart types as before
      const chartType = rawData.data[0].type;
      const isTimeSeriesPie =
        rawData.settings.originalTemplate === "segment sales";
      switch (chartType) {
        case "PIE":
          if (isTimeSeriesPie) {
            data = this.styleTimeSeriesPieChart(
              this.parseTimeSeriesPieChart(rawData.data)
            );

            layout = this.styleTimeSeriesPieChartLayout(data, layout);
          } else {
            data = this.stylePieChart(this.parsePieChart(rawData.data));
            layout = this.stylePieChartLayout(data, layout);
          }
          break;
        case "STACKEDBAR":
          data = this.styleStackedBarChart(
            this.parseStackedBarChart(rawData.data)
          );
          layout = this.styleStackedBarChartLayout(data, layout);
          break;
        case "GROUPEDBAR":
          data = this.styleGroupedBarChart(
            this.parseGroupedBarChart(rawData.data)
          );
          layout = this.styleGroupedBarChartLayout(data, layout);
          break;
        case "LINE":
          data = this.styleLineChart(this.parseLineChart(rawData.data));
          layout = this.styleLineChartLayout(data, layout);
          break;
        default:
          data = null;
      }
    }
    return { data, layout, config };
  }
  parseTimeSeriesPieChart(chartData: any[]): any[] {
    // Assuming the data is in the first (and only) item of the chartData array
    const pieData = chartData[0].data[0];

    return [
      {
        type: "pie",
        labels: pieData
          .map((item) => item.date)
          .filter((item) => item !== "#EMPTY")
          .map((item) => this.cropLabel(item)),
        values: pieData
          .map((item) => item.value)
          .filter((item) => item !== "#EMPTY"),
      },
    ];
  }

  styleTimeSeriesPieChart(data: any[]) {
    const colors = [
      "rgb(20, 83, 137)",
      "rgb(255, 214, 67)",
      "rgb(31, 119, 180)",
      "rgb(223, 233, 246)",
      "rgb(236, 236, 236)",
    ];

    data[0].hovertemplate =
      "<b>%{label}</b><br>%{percent:.1%}<br><extra></extra>";
    data[0].texttemplate = "%{label}<br> %{percent:.1%}";
    data[0].textposition = "outside";
    data[0].automargin = true;
    (data[0].outsidetextfont = {
      size: 14,
      color: "White",
    }),
      (data[0].marker = { colors });
    // You can add custom color mapping here if needed
    // data[0].marker = { colors: [...] };
    return data;
  }

  parsePieChart(chartData: any[]): any[] {
    console.log("Parsing PIE CHART", chartData);
    const values: number[] = [];
    const labels: string[] = [];

    chartData.forEach((item) => {
      if (item.data[0] !== "#EMPTY" && item.label[0] !== "#EMPTY") {
        values.push(item.data[0]);
        labels.push(this.cropLabel(item.label[0]));
        //labels.push(item.label[0]);
      }
    });

    return [
      {
        type: "pie",
        values,
        labels,
      },
    ];
  }

  cropLabel(label, maxLength = 10) {
    if (label === "F") {
      label = "Free Float";
    }
    // if (label.length > 15) {
    //   return label.slice(0, 15) + "...";
    // } else {
    //   return label + ":";
    // }
    //  return label; //+ ":";

    if (label.length <= maxLength) return label;

    const words = label.split(" ");
    const lines = [];
    let currentLine = "";

    for (const word of words) {
      if ((currentLine + word).length > maxLength) {
        if (currentLine) lines.push(currentLine);
        currentLine = word;
      } else {
        currentLine += (currentLine ? " " : "") + word;
      }
    }

    if (currentLine) lines.push(currentLine);

    return lines.join("<br>");
  }

  parseMixedChart(chartData: any[]): any[] {
    return chartData.map((item) => {
      if (item.type === "GROUPEDBAR") {
        return {
          type: "bar",
          name: item.key,
          x: item.xAxis,
          /**
           * If the values in item.data (for y) are strings with a comma,  you can replace the , with a . and multiply by 1000
           * If the values in item.data (for y) are numbers, they are ment as is (its in Hundteds of millions)
           */
          y: item.data.map((value: string) =>
            this.parseValueNumberToMillions(value)
          ),
          yaxis: "y", // Always use the primary y-axis for bars
        };
      } else if (item.type === "LINE") {
        return {
          type: "scatter",
          mode: "lines",
          name: item.key,
          x: item.xAxis,
          y: item.data.map((value: string) => parseFloat(value)),
          yaxis: "y2", // Always use the secondary y-axis for lines
        };
      }
    });
  }

  parseValueNumberToMillions(value: any): number {
    if (typeof value === "string" && value.includes(",")) {
      return parseFloat(value.replace(",", ".")) * 1000;
    } else {
      return value;
    }
  }

  parseStackedBarChart(chartData: any[]): any[] {
    console.log("Parsing Stacked bar CHART", chartData);

    return chartData.map((item) => ({
      type: "bar",
      name: item.key,
      x: item.xAxis,
      y: item.data.map((value: string) => parseFloat(value)),
    }));
  }

  parseGroupedBarChart(chartData: any[]): any[] {
    console.log("Parsing Grouped  bar CHART", chartData);

    return chartData.map((item) => ({
      type: "bar",
      name: item.key,
      x: item.xAxis,
      y: item.data,
    }));
  }

  parseLineChart(chartData: any[]): any[] {
    console.log("Parsing Line CHART", chartData);

    return chartData.map((item) => ({
      mode: "lines",
      type: "scatter",

      name: item.key,
      x: item.xAxis,
      y: item.data.map((value: string) => parseFloat(value)),
      yaxis: item.yaxis || "y",
    }));
  }

  stylePieChart(data: any[]): any[] {
    const colors = [
      "rgb(20, 83, 137)",
      "rgb(255, 214, 67)",
      "rgb(31, 119, 180)",
      "rgb(131, 171, 219)",
      "rgb(223, 233, 246)",
      "rgb(236, 236, 236)",
    ];

    return data.map((series) => ({
      ...series,
      marker: {
        colors: colors,
      },
      hovertemplate: "<b>%{label}</b><br>%{percent:.1%}<br><extra></extra>",
      texttemplate: "%{label}<br>%{percent:.1%}",
      textposition: "outside",
      automargin: true,
      outsidetextfont: {
        size: 14,
        color: "White",
      },
    }));
  }

  styleStackedBarChart(data: any[]): any[] {
    const colorMap = {
      Domestic: "rgb(20, 83, 137)",
      "Europe (ex domestic)": "rgb(255, 214, 67)",
      "The Americas": "rgb(31, 119, 180)",
      Asia: "rgb(223, 233, 246)",
      "Rest of World": "rgb(236, 236, 236)",
      // Add more mappings as needed
    };

    return data.map((series) => ({
      ...series,
      marker: { color: colorMap[series.name] || "#000000" },
      hovertemplate: "%{y} <br>%{fullData.name} sales in % <extra></extra>",
    }));
  }

  styleGroupedBarChart(data: any[], forcedColor = null): any[] {
    return data.map((series) => ({
      ...series,
      marker: { color: forcedColor ? forcedColor : "rgb(20, 83, 137)" },
      hovertemplate: "%{fullData.name}<br>%{y}<extra></extra>",

      //texttemplate: "%{y:.1f}",
    }));
  }

  styleLineChart(data: any[], forcedColor = null): any[] {
    const colors = ["rgb(20, 83, 137)", "rgb(255, 214, 67)"];

    return data.map((series, index) => ({
      ...series,

      line: { color: forcedColor ? forcedColor : colors[index], width: 4 },
      hovertemplate: "%{y:.f}<br>%{fullData.name} in %<extra></extra>",
      texttemplate: "%{y:.1f}",
    }));
  }

  styleMixedChart(data: any[]): any[] {
    return data.map((series) => {
      if (series.type === "bar") {
        return this.styleGroupedBarChart([series], "rgb(20, 83, 137)")[0];
      } else if (series.type === "scatter" && series.mode === "lines") {
        return this.styleLineChart([series], "rgb(255, 214, 67)")[0];
      }
      return series;
    });
  }

  // Navigate through chart templates
  public navigate(step: number): void {
    const currentIndex = this.index();
    const newIndex = this.calculateNewIndex(currentIndex, step);
    this.index.set(newIndex);
  }

  private setBottomLeftLegend(layout: any) {
    layout.legend = {
      ...layout.legend,
      orientation: "h",
      yanchor: "bottom",
      y: -0.2,
      xanchor: "left",
      x: 0,
    };
    //layout.maring = { ...layout.margin, b: 30 };
    layout.legend = { ...layout.legend, font: { color: "white", size: 16 } };
    // Adjust margins to make room for the legend at the bottom
    //layout.margin = { l: 50, r: 50, t: 50, b: 100 };
    return layout;
  }

  // Emit headline change event
  private emitHeadlineChange(chartingData: any): void {
    const headline = chartingData.name[chartingData.settings.language];

    this.tileEvent.emit({
      type: "headline-change",
      change: { headline },
    });
  }

  // Calculate new index for navigation
  private calculateNewIndex(currentIndex: number, step: number): number {
    const newIndex = currentIndex + step;
    const configLength = this.config().content.length;
    if (newIndex >= configLength) return 0;
    if (newIndex < 0) return configLength - 1;
    return newIndex;
  }

  private styleLineChartLayout(data: any[], layout: any) {
    layout = this.setBottomLeftLegend(layout);
    const roceLineData = data.find((item) => item.name === "ROCE");
    const waccLineData = data.find((item) => item.name === "Pretax WACC");

    if (waccLineData && roceLineData) {
      waccLineData.hovertemplate =
        "%{y:.f}><br>%{fullData.name}<extra></extra>";
      roceLineData.hovertemplate =
        "%{y:.1f}<br>%{fullData.name} in %<extra></extra>";
      layout.yaxis = {
        ...layout.yaxis,
        range: this.calculateYRangeForWACCvsROCE(
          waccLineData.y,
          roceLineData.y
        ),
      };
    }
    layout.hovermode = "x";
    return layout;
  }

  private styleTimeSeriesPieChartLayout(data: any[], layout: any) {
    layout.showlegend = false;
    //layout.hoverinfo = "text+y";
    layout.hoverlabel = { namelength: 5 };

    layout.margin = { ...layout.margin, b: 50, t: 50, r: 50, l: 50 };
    return layout;
  }

  private styleStackedBarChartLayout(data: any[], layout: any) {
    layout = this.setBottomLeftLegend(layout);
    layout.legend.font = { ...layout.legend.font, size: 14 };
    layout.hoverinfo = "text+y";
    layout.hovermode = "x";
    layout.hoverlabel = { namelength: -1 };
    return layout;
  }

  private styleGroupedBarChartLayout(data: any[], layout: any) {
    layout = this.setBottomLeftLegend(layout);
    layout.hoverinfo = "text+y";
    layout.hovermode = "x";
    layout.hoverlabel = { namelength: -1 };

    return layout;
  }

  private styleMixedChartLayout(data: any[], layout: any) {
    layout = this.setBottomLeftLegend(layout);
    layout.yaxis = {
      title: "",
      side: "right",
      showgrid: false,
      zeroline: false,
      tickfont: {
        size: 16,
        color: "white",
      },
    };
    layout.yaxis2 = {
      title: "",
      overlaying: "y",
      side: "left",
      showgrid: false,
      zeroline: false,
      tickfont: {
        size: 16,
        color: "white",
      },
    };
    layout.yaxis2 = {
      ...layout.yaxis2,
      // range: this.calculateYRangeForMixedChart(waccLineData.y, roceLineData.y),
    };
    layout.hoverinfo = "text+y";
    layout.hovermode = "x";
    layout.hoverlabel = { namelength: -1 };

    return layout;
  }

  private stylePieChartLayout(data: any[], layout: any) {
    layout.showlegend = false;
    layout.hoverlabel = { namelength: 5 };
    layout.margin = { ...layout.margin, b: 50, t: 50, r: 50, l: 50 };

    return layout;
  }

  private calculateYRangeForWACCvsROCE(
    waccLineData: number[],
    roceLineData: number[]
  ): [number, number] {
    const avgWacc =
      waccLineData.reduce((acc, val) => acc + (val || 0), 0) /
      waccLineData.length;
    const min = Math.min(...roceLineData);
    const max = Math.max(...roceLineData);
    const buffer = 5; // Add a 5% buffer to the top of the range

    let topOfRange: number;

    if (!avgWacc || (avgWacc > min && avgWacc < max)) {
      topOfRange = Math.ceil(max);
    } else if (avgWacc >= max) {
      topOfRange = Math.ceil(avgWacc * 1.5);
    } else {
      topOfRange = Math.ceil(max);
    }

    // Add the buffer to the top of the range
    topOfRange += buffer;

    // Ensure the bottom of the range is not changed
    const bottomOfRange =
      avgWacc <= max ? Math.floor(avgWacc * 0.8) : Math.floor(min);

    return [bottomOfRange, topOfRange];
  }

  private calculateYRangeForMixedChart(arr) {
    arr = arr.map((str) => parseFloat(str.replace(",", "")));
    const min = Math.min(...arr);
    const max = Math.max(...arr);

    const roundedMin = Math.floor(min * 0.5);
    const roundedMax = Math.ceil(max * 1.5);

    return { roundedMin, roundedMax };
  }
}
