import { Clipboard } from "@angular/cdk/clipboard";
import { AsyncPipe, DecimalPipe, NgClass } from "@angular/common";
import { Component, ElementRef, ViewChild } from "@angular/core";
import { ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

import { FileService } from "~features/file-manager/file.service";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
  standalone: true,
  imports: [ReactiveFormsModule, AsyncPipe, DecimalPipe, NgClass],
})
export class FileUploadComponent {
  @ViewChild("fileInput") fileInput: ElementRef<HTMLInputElement>;
  isLoading$ = new BehaviorSubject<boolean>(false);
  selectedFiles$ = new BehaviorSubject<
    {
      actualFile: any;
      name: string;
      url: string;
      type: string;
      size: number;
      hideFromGoogle: boolean;
      control: UntypedFormControl;
      uploaded: boolean;
    }[]
  >([]);
  uploadMessage$ = new BehaviorSubject<string>("");
  uploaded$ = new BehaviorSubject<boolean>(false);
  hasSelectedFiles$ = this.selectedFiles$.pipe(
    map((files) => files.length > 0)
  );

  constructor(private fileService: FileService, private clipboard: Clipboard) {}

  onFileSelected(event: any) {
    const files = event.target.files;
    const fileArray = Array.from(files).map((file: File) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise<{
        actualFile: any;
        name: string;
        url: string;
        type: string;
        size: number;
        hideFromGoogle: boolean;
        control: UntypedFormControl;
        uploaded: boolean;
      }>((resolve) => {
        reader.onload = () => {
          resolve({
            actualFile: file,
            name: file.name,
            url: reader.result as string,
            type: file.type,
            size: file.size,
            hideFromGoogle: false,
            control: new UntypedFormControl({
              value: file.name,
              disabled: false,
            }),
            uploaded: false,
          });
        };
      });
    });

    Promise.all(fileArray).then((files) => {
      this.selectedFiles$.next([...this.selectedFiles$.value, ...files]);
    });
  }

  onFileNameChange(index: number) {
    const files = this.selectedFiles$.getValue();
    const updatedFiles = files.map((file, i) => {
      if (i === index) {
        return { ...file, name: file.control.value };
      }
      return file;
    });
    this.selectedFiles$.next(updatedFiles);
  }

  onFileDeselect(index: number) {
    const files = this.selectedFiles$.getValue();
    const updatedFiles = files.filter((_, i) => i !== index);
    this.selectedFiles$.next(updatedFiles);
  }

  onHideFromGoogleChange(index: number) {
    const files = this.selectedFiles$.getValue();
    const updatedFiles = files.map((file, i) => {
      if (i === index) {
        return { ...file, hideFromGoogle: !file.hideFromGoogle };
      }
      return file;
    });
    this.selectedFiles$.next(updatedFiles);
  }

  uploadFiles() {
    const files = this.selectedFiles$.getValue();
    const filesToUpload = files.filter((file) => !file.uploaded);

    if (filesToUpload.length === 0) {
      this.uploadMessage$.next("No files selected for upload.");
      return;
    }
    this.isLoading$.next(true);
    const formData = new FormData();
    filesToUpload.forEach((file) => {
      // console.log("Appending file:", file.name);
      formData.append("files", file.actualFile, file.name);
    });

    //  console.log("Uploading files:", filesToUpload);

    this.fileService.uploadFiles(formData).subscribe(
      (response) => {
        //  console.log("Upload response:", response);
        //  console.log(response.files);
        const uploadedFiles = files.map((file) => {
          const uploadedFile = response.files.find((uploadedFile: string) =>
            uploadedFile.includes(`${file.name.split(".")[0]}__`)
          );

          return {
            ...file,
            uploaded: true,
            url: uploadedFile, //|| "https://downloads.research-hub.de/" + file.name, // it is always this contated string given back to the user
            control: new UntypedFormControl({
              value: file.name,
              disabled: true,
            }),
          };
        });
        this.selectedFiles$.next(uploadedFiles);
        this.uploadMessage$.next("Files uploaded successfully!");
        this.uploaded$.next(true);
        this.isLoading$.next(false);
      },
      (error) => {
        console.error("Error uploading files:", error);
        this.uploadMessage$.next("File upload failed. Please try again.");
        this.isLoading$.next(false);
      }
    );
  }

  uploadMoreFiles() {
    this.selectedFiles$.next([]);
    this.uploadMessage$.next("");
    this.uploaded$.next(false);
    this.fileInput.nativeElement.value = "";
  }
  copyTextToClipboard(textToCopy: string) {
    this.clipboard.copy(textToCopy);
  }
}
