import { HttpClient } from "@angular/common/http";
import {
  effect,
  inject,
  Injectable,
  signal,
  WritableSignal,
} from "@angular/core";
import { catchError, finalize, map } from "rxjs/operators";
import { IApiResponse } from "~global-interfaces/IApiResponse.";
import { ICompany } from "~global-interfaces/ICompany";

import { alsterAPI_URL } from "~api/api-urls";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { CompanylistService } from "~features/company/companylist.service";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { AdminRequestService } from "~shared/services/admin-request.service";
import { ErrorHandlingService } from "~shared/services/error-handling.service";
import { LoadingIndicatorService } from "~shared/services/loading-indicator.service";

@Injectable({
  providedIn: "root",
})
export class SwotService {
  isLoading: WritableSignal<boolean> = signal(false);
  adminRequestService = inject(AdminRequestService);
  errorHandlingService = inject(ErrorHandlingService);
  loadingIndicatorService = inject(LoadingIndicatorService);
  private readonly customerId = "5eed24ff4fe2bd337ccab5d3";
  private readonly apiToken = "cqt1bii3tq5fuzo0c5id";
  private http = inject(HttpClient);
  private companyTickerService = inject(CompanyTickerService);
  private companyTicker: WritableSignal<string> = signal("");
  private companySWOT: WritableSignal<any> = signal({});
  private companyListService = inject(CompanylistService);
  private companyList = this.companyListService.getCompanyList();

  constructor() {
    effect(() => {
      const companyTicker = this.companyTickerService.getCompanyTicker();
      if (this.companyList() && companyTicker()) {
        this.fetchSWOT(companyTicker());
      }
    }, enableSignalWritesInEffectContext);
  }

  getisLoading() {
    return this.isLoading;
  }

  public getSwot() {
    return this.companySWOT;
  }

  fetchSWOT(companyTicker) {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<any>>(alsterAPI_URL + "swot/" + companyTicker)
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "fetching swot")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (companySWOT) => {
          this.companySWOT.set(companySWOT);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch swot success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  updateSWOT(formData) {
    this.isLoading.set(true);
    this.http
      .put<IApiResponse<ICompany>>(
        alsterAPI_URL + "swot/edit/" + formData._id,
        formData
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "update swot")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (companyData) => {
          this.companySWOT.set(companyData);
          this.adminRequestService.setLastRequestMessage("10", true);
          this.adminRequestService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  createSWOT(formData) {
    this.isLoading.set(true);
    this.http
      .post<IApiResponse<ICompany>>(alsterAPI_URL + "swot/create", formData)
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "createSwot")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (companyData) => {
          this.companySWOT.set(companyData);
          this.adminRequestService.setLastRequestMessage("11", true);
          this.adminRequestService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }
}
