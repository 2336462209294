<div class="bg-gray-alster text-white p-4 rounded-lg max-w-3xl mx-auto">
  <div class="flex justify-between items-center mb-4">
    <h2 class="text-lg font-semibold">Investor Profile</h2>
    <button (click)="dialogRef.close()" class="text-gray-400 hover:text-white">
      <svg
        class="h-5 w-5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 18L18 6M6 6l12 12"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </svg>
    </button>
  </div>

  <div class="bg-blue-100 rounded-lg p-4 mb-4">
    <h3 class="text-md font-semibold mb-1 text-black">{{ person.company }}</h3>
    <p class="text-base mb-1 text-black">
      {{ person.firstName }} {{ person.lastName }}
    </p>
    <p class="text-sm text-gray-400">
      {{
        investorProfileMapping["investorType"].values[
          investorProfile["investorType"] - 1
        ]
      }}
    </p>
  </div>

  @if (profileHasDetails()) {
    <div class="mb-4">
      <h4 class="text-md font-semibold mb-3">Details</h4>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
        @for (item of investorProfile | keyvalue: keyAscOrder; track item) {
          <ng-container>
            @if (item.key !== "investorType" && item.key !== "description") {
              <div class="bg-blue-100 p-3 rounded-lg">
                <p class="text-xs text-gray-400 mb-1">
                  {{ investorProfileMapping[item.key].title }}
                </p>
                <p class="text-sm text-black">
                  {{ investorProfileMapping[item.key].values[item.value - 1] }}
                </p>
              </div>
            }
          </ng-container>
        }
      </div>
    </div>
  }

  @if (investorProfile.description) {
    <div>
      <h4 class="text-md font-semibold mb-3">
        {{ investorProfileMapping["description"].title }}
      </h4>
      <p class="text-sm bg-blue-100 text-black p-3 rounded-lg">
        {{ investorProfile.description }}
      </p>
    </div>
  }
</div>
