<form
  [formGroup]="rssEntryForm"
  class="mx-auto bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4"
>
  <div class="mb-4">
    <label class="block text-gray-700 text-sm font-bold mb-2" for="title"
      >Language: {{ rssEntryForm.get("lang").value }}</label
    >
  </div>
  <div class="mb-4">
    <label class="block text-gray-700 text-sm font-bold mb-2" for="title"
      >Creator: {{ rssEntryForm.get("creator").value }}</label
    >
  </div>
  <div class="mb-4">
    <label class="block text-gray-700 text-sm font-bold mb-2" for="title"
      >link: {{ rssEntryForm.get("link").value }}</label
    >
  </div>
  <div class="mb-4">
    <label class="block text-gray-700 text-sm font-bold mb-2" for="title"
      >date: {{ rssEntryForm.get("pubDate").value }}</label
    >
  </div>

  <div class="mb-4">
    <label class="block text-gray-700 text-sm font-bold mb-2" for="title"
      >Title:</label
    >
    <input
      [ngClass]="{
        'border-red-500':
          rssEntryForm.get('title')?.invalid &&
          rssEntryForm.get('title')?.touched
      }"
      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      formControlName="title"
      id="title"
      required
      type="text"
    />
    @if (
      rssEntryForm.get("title")?.invalid && rssEntryForm.get("title")?.touched
    ) {
      <p class="text-red-500 text-xs italic mt-1">
        {{ getErrorMessage("title") }}
      </p>
    }
  </div>
  <div class="mb-4">
    <label class="block text-gray-700 text-sm font-bold mb-2" for="description"
      >Description:</label
    >
    <textarea
      [ngClass]="{
        'border-red-500':
          rssEntryForm.get('description')?.invalid &&
          rssEntryForm.get('description')?.touched
      }"
      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      formControlName="description"
      id="description"
      required
    ></textarea>
    @if (
      rssEntryForm.get("description")?.invalid &&
      rssEntryForm.get("description")?.touched
    ) {
      <p class="text-red-500 text-xs italic mt-1">
        {{ getErrorMessage("description") }}
      </p>
    }
  </div>

  @if (!multiMode) {
    <div class="flex items-center justify-between">
      <app-admin-form-button
        (click)="saveForm()"
        [class]="'admin-form-button'"
        [disabled]="!rssEntryForm.valid"
      >
      </app-admin-form-button>
    </div>
    <app-admin-form-request-message></app-admin-form-request-message>
  }
</form>
