<div class="flex flex-col min-h-screen">
  <section class="bg-blue-alster py-8 lg:py-12 overflow-hidden relative">
    <div class="absolute inset-0 flex justify-end items-end">
      <img alt="Symbol" class="mix-blend-darken" src="assets/intro.jpg" />
    </div>
    <div class="container mx-auto px-16 max-w-screen-2xl relative">
      <div class="col-span-12 flex justify-start">
        <h1 class="text-lgx lg:text-3xl ml-0 lg:ml-3 mt-4">
          Your platform for high-quality <br /><span class="text-blue-500"
            >research and events</span
          >
        </h1>
      </div>
      <div class="lg:-mx-6">
        <app-auto-slider-landing-page
          class="w-full"
        ></app-auto-slider-landing-page>
      </div>
    </div>
  </section>

  <section class="bg-yellow-100 py-8 lg:py-12">
    <div class="container mx-auto px-16 max-w-screen-2xl">
      <div class="grid grid-cols-1 lg:grid-cols-5 gap-4 py-4">
        <div
          class="lg:col-span-3 text-lg lg:text-xl leading-none font-semibold"
        >
          Gain access to
          <div class="text-yellow-500 font-semibold">companies through us</div>
        </div>
        <div class="lg:col-span-2">
          <p class="text-md mt-4 lg:mt-0">
            Get in touch with key people in interesting companies. Register now
            for upcoming roadshows, roundtables and conferences.
          </p>
        </div>
      </div>
    </div>
    <div class="container mx-auto px-16 pt-6 max-w-screen-2xl">
      <hr class="mb-4" />
    </div>
    <div class="container mx-auto px-16 max-w-screen-2xl">
      <app-event-grid-mixed></app-event-grid-mixed>
    </div>
  </section>

  <section class="bg-white py-8 lg:py-12">
    <div class="container mx-auto px-16 max-w-screen-2xl">
      <div class="grid grid-cols-1 lg:grid-cols-5 gap-4 py-4">
        <div
          class="lg:col-span-3 text-lg lg:text-xl leading-none font-semibold"
        >
          Find the research
          <div class="text-blue-500 font-semibold">you are looking for</div>
        </div>
        <div class="lg:col-span-2">
          <p class="text-md mt-4 lg:mt-0">
            We frequently publish updates and the universe of companies we cover
            is continuously expanding. So make sure to come back and check
            regularly.
          </p>
        </div>
      </div>
    </div>
    <div class="container mx-auto px-16 max-w-screen-2xl">
      <hr class="my-4" />
    </div>
    <div class="container mx-auto px-16 my-8 lg:my-12 max-w-screen-2xl">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8">
        <div class="col-span-1 text-left">
          <app-company-search class="grid"></app-company-search>
        </div>
        <div class="col-span-1 text-center lg:text-right mt-4 lg:mt-0">
          <a [routerLink]="'company-screener'">
            <button class="btn btn-transparent-blue text-base">
              Try advanced search <i class="fas fa-arrow-right"></i>
            </button>
          </a>
        </div>
      </div>
    </div>
    <div class="container mx-auto px-16 max-w-screen-2xl">
      <h4
        class="text-gray-muted uppercase font-normal tracking-widest leading-7 mb-8"
      >
        recent updates
      </h4>
      <app-company-grid-highlight></app-company-grid-highlight>
    </div>
  </section>

  <section class="hidden lg:block bg-yellow-100 py-8 lg:py-12">
    <div class="container mx-auto px-16 max-w-screen-2xl">
      <div class="grid grid-cols-1 lg:grid-cols-5 gap-4 py-4">
        <div
          class="lg:col-span-3 text-lg lg:text-xl leading-none font-semibold"
        >
          Find and compare
          <div class="text-yellow-500 font-semibold">interesting companies</div>
        </div>
        <div class="lg:col-span-2">
          <p class="text-md mt-4 lg:mt-0">
            Select your own search criteria to find what you are looking for in
            our coverage.
          </p>
        </div>
      </div>
    </div>
    <div class="container mx-auto px-16 py-6 max-w-screen-2xl">
      <hr class="mb-4" />
    </div>
    <div class="container mx-auto px-16 max-w-screen-2xl">
      <app-company-screener></app-company-screener>
    </div>
  </section>

  <section class="bg-white py-8 lg:py-12">
    <div class="container mx-auto px-16 max-w-screen-2xl">
      <div class="grid grid-cols-1 lg:grid-cols-5 gap-4 py-4">
        <div
          class="lg:col-span-3 text-lg lg:text-xl leading-none font-semibold"
        >
          Find the research
          <div class="text-blue-500 font-semibold">you are looking for</div>
        </div>
        <div class="lg:col-span-2">
          <p class="text-md mt-4 lg:mt-0">
            We frequently publish updates and the universe of companies we cover
            is continuously expanding. So make sure to come back and check
            regularly.
          </p>
        </div>
      </div>
    </div>
    <div class="container mx-auto px-16 py-6 max-w-screen-2xl">
      <hr class="mb-4" />
    </div>
    <div class="container mx-auto px-16 max-w-screen-2xl">
      <h4
        class="text-gray-muted uppercase font-normal tracking-widest leading-7 mb-8"
      >
        all stocks
      </h4>
      <app-company-grid></app-company-grid>
    </div>
  </section>

  <section class="py-8 lg:py-12">
    <div class="container mx-auto px-16 max-w-screen-2xl">
      <app-weekly-screener-teaser-list></app-weekly-screener-teaser-list>
    </div>
  </section>

  <section class="bg-white py-8 lg:py-12">
    <div class="container mx-auto px-16 max-w-screen-2xl">
      <app-subscribe-banner></app-subscribe-banner>
    </div>
  </section>
</div>
