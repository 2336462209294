@if (showHeaderAndFooter) {
  <div class="flex flex-col min-h-screen">
    <app-header class="sticky z-50 h-10 lg:h-14"></app-header>
    @if (frontendConfig()?.activeTransaction) {
      <app-transactionbanner
        [frontendConfig]="frontendConfig"
      ></app-transactionbanner>
    }
    <!-- Contact Widget -->
    @if (isAdmin()) {
      <app-simple-loading-indicator></app-simple-loading-indicator>
    }
    <div class="flex-grow">
      <app-creation-process-menu></app-creation-process-menu>
      <app-create-research-process></app-create-research-process>
      <router-outlet></router-outlet>
    </div>
    <app-footer [isCookiesAccepted]="isCookiesAccepted"></app-footer>
  </div>
}

@if (!showHeaderAndFooter) {
  <div class="flex-grow">
    <app-simple-loading-indicator></app-simple-loading-indicator>
    @if (comanyListIsLoaded()) {
      <router-outlet></router-outlet>
    }
  </div>
}
<app-error-message></app-error-message>
