import { HttpClient } from "@angular/common/http";
import {
  effect,
  inject,
  Injectable,
  signal,
  WritableSignal,
} from "@angular/core";
import { Router } from "@angular/router";
import { catchError, finalize, map } from "rxjs/operators";
import { IApiResponse } from "~global-interfaces/IApiResponse.";
import { IEvent } from "~global-interfaces/IEvent";
import { IQueryParams } from "~global-interfaces/IQueryParams";

import { alsterAPI_URL } from "~api/api-urls";
import { AuthService } from "~features/auth/auth.service";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { LoadingIndicatorService } from "~shared/services/loading-indicator.service";
import { PaginationService } from "~shared/services/pagination.service";

@Injectable({
  providedIn: "root",
})
export class EventService extends PaginationService<IEvent> {
  override http = inject(HttpClient);
  getAllForRegisteredUser;
  companiesWithEventsWithVideos: WritableSignal<string[] | null> = signal<
    string[] | null
  >(null);
  isLoading: WritableSignal<boolean> = signal(false);
  loadingIndicatorService = inject(LoadingIndicatorService);
  router = inject(Router);
  private authService = inject(AuthService);
  private event: WritableSignal<IEvent | null> = signal<IEvent | null>(null);
  private userRegisteredEvents: WritableSignal<IEvent[] | null> = signal<
    IEvent[] | null
  >(null);
  private nextEvent: WritableSignal<IEvent | null> = signal<IEvent | null>(
    null
  );
  private companyEvents: WritableSignal<IEvent[] | null> =
    signal<IEvent[]>(null);
  private registrationEvent: WritableSignal<IEvent | null> =
    signal<IEvent | null>(null);
  private companyTickerService = inject(CompanyTickerService);
  companyTicker = this.companyTickerService.getCompanyTicker();

  constructor() {
    super(inject(HttpClient));
    this.fetchCompaniesWithEventsWithVideos();
    if (this.authService.isAuthenticated()) {
      this.fetchUserRegisteredEvents();
    }
    effect(() => {
      if (this.companyTicker()) {
        this.fetchCompanyEvents(this.companyTicker());
      }
    }, enableSignalWritesInEffectContext);
  }

  getisLoading() {
    return this.isLoading;
  }

  fetchCompaniesWithEventsWithVideos() {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<string[]>>(`${alsterAPI_URL}events/eventcompanies`)
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "fetch companies with events with videos"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (listOfCOmpnaies: string[]) => {
          this.companiesWithEventsWithVideos.set(listOfCOmpnaies);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch companies with events with videos success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  fetchEventsList(
    paginationParams: IQueryParams = { page: 1, limit: 25 },
    afterdate: string = undefined
  ) {
    if (afterdate) {
      paginationParams = { ...paginationParams, afterdate };
    }
    // console.log(afterdate, paginationParams);
    this.isLoading.set(true);
    this.fetchItems(alsterAPI_URL + "events", paginationParams).subscribe(
      ({ items, totalCount }) => {
        this.items.set(items);
        this.totalCount.set(totalCount || 0);
        this.isLoading.set(false);
      }
    );
  }

  fetchEvent(eventId: string) {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<IEvent>>(`${alsterAPI_URL}events/${eventId}`)
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "fetch event")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (event: IEvent) => {
          this.event.set(event);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch event success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  updateEvent(formData) {
    const body = { ...formData };
    this.isLoading.set(true);
    this.http
      .put<IApiResponse<IEvent>>(
        `${alsterAPI_URL}events/edit/${formData._id}`,
        body
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "update event")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (event) => {
          this.event.set(event);
          this.adminRequestService.setLastRequestSuccess(true);
          this.adminRequestService.setLastRequestMessage("21", true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  createEvent(formData) {
    const body = { ...formData };
    this.isLoading.set(true);
    this.http
      .post<IApiResponse<IEvent>>(`${alsterAPI_URL}events/create`, body)
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "create event")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (event) => {
          this.event.set(event);
          this.adminRequestService.setLastRequestSuccess(true);
          this.adminRequestService.setLastRequestMessage("13", true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  fetchNextEvent() {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<IEvent>>(alsterAPI_URL + "events/next")
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "fetch next event")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (event: IEvent) => {
          this.nextEvent.set(event);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch next event success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  fetchCompanyEvents(companyTicker: string) {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<IEvent[]>>(
        `${alsterAPI_URL}events/company/${companyTicker}` // ?videomustexist=false
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "fetch company events")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (events: IEvent[]) => {
          this.companyEvents.set(events);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch company events success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  fetchUserRegisteredEvents() {
    this.isLoading.set(true);
    const usermail = this.authService.getCurrentUserEmail();
    // console.log("GET REGISTERED EVENTS FOR , ", usermail);
    this.http
      .get<IApiResponse<IEvent[]>>(`${alsterAPI_URL}events/user/${usermail}`)
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "fetch user registered events"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (events: IEvent[]) => {
          this.userRegisteredEvents.set(events);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch user registered events success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  getEventsWithSIBContext(currentUsersCompanyBloombergticker: string) {
    const url = `${alsterAPI_URL}events/company/${currentUsersCompanyBloombergticker}`;
    return this.http.get(url);
  }

  getSingleEventWithSIBContext(listID: string) {
    const url = `${alsterAPI_URL}events/event/${listID}`;
    return this.http.get(url);
  }

  fetchRegistrationEvent(date: string, ticker: string) {
    this.isLoading.set(true);
    const url = `${alsterAPI_URL}events/find/${date}/${ticker}`;
    this.http
      .get<IApiResponse<IEvent>>(url)
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "fetch event for registration"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (event: IEvent) => {
          this.registrationEvent.set(event);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  registerForEvent(email: string, listId: string) {
    const body = { email, listId };
    const url = `${alsterAPI_URL}sib/events/addcontact`;
    return this.http.post(url, body);
  }

  deleteEvent(eventId: string) {
    this.isLoading.set(true);
    this.http
      .delete<IApiResponse<any>>(alsterAPI_URL + "events/delete/" + eventId)
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "deletion of event ")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: () => {
          this.adminRequestService.setLastRequestMessage("29", true);
          this.adminRequestService.setLastRequestSuccess(true);
          this.router.navigate(["/admin/dashboard/events/"]);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  // Getter methods for signals

  public getUserRegisteredEvents() {
    return this.userRegisteredEvents;
  }

  public getCurrentEvent() {
    return this.event;
  }

  public getEventsList() {
    return this.items;
  }

  public getNextEvent() {
    return this.nextEvent;
  }

  public getCompanyEvents() {
    return this.companyEvents;
  }
  public getRegistrationEvent() {
    return this.registrationEvent;
  }

  public getLogoForCompanyFromEvent(): string {
    return this.event().companyLogoURL;
  }

  public getCompaniesWithEventsWithVideos() {
    return this.companiesWithEventsWithVideos;
  }
}
