import { CdkAccordion, CdkAccordionItem } from "@angular/cdk/accordion";
import {
  AfterViewInit,
  Component,
  computed,
  ElementRef,
  inject,
  OnInit,
  QueryList,
  signal,
  ViewChildren,
  WritableSignal,
} from "@angular/core";
import { IResearch } from "~global-interfaces/IResearch";
import { IRSSEntry } from "~global-interfaces/IRSSEntry";

import { CompanyExchangeMappingEditorFormComponent } from "~features/company/admin/company-exchange-mapping/company-exchange-mapping-editor-form/company-exchange-mapping-editor-form.component";
import { CompanyInitiationNoteFormComponent } from "~features/company/admin/company-initiation-note/company-initiation-note-form/company-initiation-note-form.component";
import { CompanyInvestmentcaseFormComponent } from "~features/company/admin/company-investmentcase/company-investmentcase-form/company-investmentcase-form.component";
import { CompanyLeewayConnectionFormComponent } from "~features/company/admin/company-leeway/company-leeway-connection-form/company-leeway-connection-form.component";
import { AdminCompanyResearchEditorFormComponent } from "~features/company/admin/company-research/admin-company-research-editor-form/admin-company-research-editor-form.component";
import { AdminCompanySettingsEditorFormComponent } from "~features/company/admin/company-settings/admin-company-settings-editor-form/admin-company-settings-editor-form.component";
import { AdminCompanySwotEditorFormComponent } from "~features/company/admin/company-swot/admin-company-swot-editor-form/admin-company-swot-editor-form.component";
import { CompanyValuationEditorFormComponent } from "~features/company/admin/company-valuation/company-valuation-editor-form/company-valuation-editor-form.component";
import { CompanySearchComponent } from "~features/company/company-search/company-search.component";
import { ExcelService } from "~features/company/excel.service";
import { FileUploadComponent } from "~features/file-manager/file-upload/file-upload.component";
import { ResearchService } from "~features/research/research.service";
import { AdminRssEditorFormComponent } from "~features/rss/admin/admin-rss-editor-form/admin-rss-editor-form.component";
import { RssEntryService } from "~features/rss/rss-entry.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { INITIAL_RESEARCH } from "~shared/initial-values";
import { AdminRequestService } from "~shared/services/admin-request.service";
import { ErrorHandlingService } from "~shared/services/error-handling.service";

@Component({
  selector: "app-guided-research-upload",
  standalone: true,
  imports: [
    AdminCompanySettingsEditorFormComponent,
    AdminSidePanelComponent,
    BreadcrumpComponent,
    FileUploadComponent,
    CompanySearchComponent,
    AdminCompanyResearchEditorFormComponent,
    AdminCompanySwotEditorFormComponent,
    AdminFormRequestMessageComponent,
    CdkAccordion,
    CdkAccordionItem,
    CompanyExchangeMappingEditorFormComponent,
    CompanyInitiationNoteFormComponent,
    CompanyInvestmentcaseFormComponent,
    CompanyLeewayConnectionFormComponent,
    CompanyValuationEditorFormComponent,
    AdminRssEditorFormComponent,
  ],
  templateUrl: "./guided-research-upload.component.html",
  styleUrl: "./guided-research-upload.component.scss",
})
export class GuidedResearchUploadComponent implements OnInit, AfterViewInit {
  steps = [
    { id: "research", name: "Upload Research", optional: false },
    { id: "publishfigures", name: "Publish Excel Figures", optional: true },
    { id: "rssgerman", name: "RSS DE", optional: true },
    { id: "rssenglish", name: "RSS EN", optional: true },
  ];
  @ViewChildren("stepElement") stepElements!: QueryList<ElementRef>;

  currentStep = signal(0);

  researchInitialData: WritableSignal<any> =
    signal<IResearch>(INITIAL_RESEARCH);

  rssGermanInitialData: WritableSignal<IRSSEntry | null> =
    signal<IRSSEntry | null>(null);
  rssEnglishInitialData: WritableSignal<IRSSEntry | null> =
    signal<IRSSEntry | null>(null);

  formValidityStatus = signal<{ [key: string]: boolean }>({});
  formFinishStatus = signal<{ [key: string]: boolean }>({});
  allFormsValid = computed(() =>
    this.steps.every(
      (step) => step.optional || this.formValidityStatus()[step.id]
    )
  );
  allFormsFinished = computed(() =>
    this.steps.every(
      (step) => step.optional || this.formFinishStatus()[step.id]
    )
  );

  formValues = signal<{ [key: string]: any }>({ saveresearch: false });

  researchService = inject(ResearchService);

  excelService = inject(ExcelService);
  currentCompanyTicker: WritableSignal<string | null> = signal(null);
  currentCompanyName: WritableSignal<string | null> = signal(null);
  adminRequestService = inject(AdminRequestService);
  rssEntryService = inject(RssEntryService);
  errorHandlingService = inject(ErrorHandlingService);

  constructor() {}

  ngAfterViewInit() {
    this.setupScrollObserver();
  }

  onCompanySelected(company) {
    this.currentCompanyTicker.set(company.companyTicker);
    this.currentCompanyName.set(company.name);
  }

  setupScrollObserver() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const stepIndex = parseInt(entry.target.id.split("-")[1]);
            this.currentStep.set(stepIndex);
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the element is visible
    );

    this.stepElements.forEach((stepElement) => {
      observer.observe(stepElement.nativeElement);
    });
  }

  ngOnInit() {
    // Initialize form validity status
    this.formValidityStatus.set(
      this.steps.reduce((acc, step) => ({ ...acc, [step.id]: false }), {})
    );
  }

  updateFormValidity(formName: string, isValid: boolean) {
    this.formValidityStatus.update((prev) => ({
      ...prev,
      [formName]: isValid,
    }));
  }

  updateFormFinishedStatus(formName: string, isFinished: boolean) {
    this.formFinishStatus.update((prev) => ({
      ...prev,
      [formName]: isFinished,
    }));
  }

  updateFormValues(formName: string, values: any) {
    this.formValues.update((prev) => ({ ...prev, [formName]: values }));
  }

  submitAll() {
    /**
     * Publush Excel Figures is triggered before this step
     *
     */

    const { research, rssgerman, rssenglish } = this.formValues();
    console.log(rssgerman, rssenglish);
    if (research) {
      this.researchService.createResearch(research);
      this.updateFormFinishedStatus("research", true);
    }
    if (rssgerman) {
      this.rssEntryService.createRssEntry(rssgerman).subscribe(() => {
        this.updateFormFinishedStatus("rssgerman", true);
      });
    }
    if (rssenglish) {
      this.rssEntryService.createRssEntry(rssenglish).subscribe(() => {
        this.updateFormFinishedStatus("rssenglish", true);
      });
    }
  }

  publishExcelFigures() {
    this.excelService
      .publicPublishExcelFiguresForCompany(this.currentCompanyTicker())
      .subscribe({
        next: () => {
          this.adminRequestService.setLastRequestMessage("23", true);
          this.adminRequestService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
    this.updateFormFinishedStatus("publishfigures", true);
    this.updateFormValidity("publishfigures", true);
  }
}
