import { Component, inject, signal, WritableSignal } from "@angular/core";
import { RouterLink } from "@angular/router";
import { IEvent } from "~global-interfaces/IEvent";

import { EventService } from "~features/events/event.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { PaginationComponent } from "~shared/components/ui/pagination/pagination.component";

@Component({
  selector: "app-admin-event-list",
  standalone: true,
  imports: [
    BreadcrumpComponent,
    RouterLink,
    PaginationComponent,
    AdminSidePanelComponent,
  ],
  templateUrl: "./admin-event-list.component.html",
  styleUrls: ["./admin-event-list.component.scss"],
})
export class AdminEventListComponent {
  eventService = inject(EventService);
  eventsList: WritableSignal<IEvent[]> = signal<IEvent[]>([]);
  totalItems = signal(0);
  currentPage = signal(1);
  itemsPerPage = signal(10);

  constructor() {
    this.fetchEvents();
  }

  fetchEvents() {
    this.eventService.fetchEventsList({
      page: this.currentPage(),
      limit: this.itemsPerPage(),
    });
    this.eventsList = this.eventService.getEventsList();
    this.totalItems = this.eventService.getTotalCount();
  }

  fetchMoreEvents($event: number) {
    this.currentPage.set($event);
    this.fetchEvents();
  }

  getStartIndex(): number {
    return (this.currentPage() - 1) * this.itemsPerPage() + 1;
  }

  getEndIndex(): number {
    const endIndex = this.currentPage() * this.itemsPerPage();
    return Math.min(endIndex, this.totalItems());
  }
}
