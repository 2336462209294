import { Dialog } from "@angular/cdk/dialog";
import { NgClass } from "@angular/common";
import { Component, inject, Input } from "@angular/core";

import { ResponsiveVideoComponent } from "~features/video/responsive-video/responsive-video.component";
import { VideoDialogComponent } from "~features/video/video-dialog/video-dialog.component";
import { DialogService } from "~shared/services/dialog.service";

@Component({
  selector: "app-video-player",
  standalone: true,
  imports: [ResponsiveVideoComponent, NgClass],
  templateUrl: "./video-player.component.html",
  styleUrl: "./video-player.component.scss",
})
export class VideoPlayerComponent {
  @Input() videoURL: string = "";
  @Input() openInDialog: boolean = false;
  @Input() redirectToURL: boolean = false;

  dialogService = inject(DialogService);
  constructor(private dialog: Dialog) {}

  handleClick() {
    if (this.openInDialog) {
      this.openVideoDialog();
    }
  }

  private openVideoDialog() {
    this.dialogService.open(VideoDialogComponent, {
      maxWidth: "80%",
      data: { videoURL: this.videoURL },
    });
  }
}
