import { HttpClient } from "@angular/common/http";
import { inject, Injectable, signal, WritableSignal } from "@angular/core";
import { catchError, finalize, map } from "rxjs/operators";
import { IApiResponse } from "~global-interfaces/IApiResponse.";
import { IQueryParams } from "~global-interfaces/IQueryParams";
import { IScreenerArticle } from "~global-interfaces/IScreenerArticle";

import { alsterAPI_URL } from "~api/api-urls";
import { INITIAL_SCREENERARTICLE } from "~shared/initial-values";
import { LoadingIndicatorService } from "~shared/services/loading-indicator.service";
import { PaginationService } from "~shared/services/pagination.service";

@Injectable({
  providedIn: "root",
})
export class WeeklyScreenerService extends PaginationService<any> {
  override http = inject(HttpClient);
  isLoading: WritableSignal<boolean> = signal(false);
  loadingIndicatorService = inject(LoadingIndicatorService);
  private weeklyScreener: WritableSignal<IScreenerArticle> = signal(
    INITIAL_SCREENERARTICLE
  );

  constructor(http: HttpClient) {
    super(http);
    ///console.log("RUNNING WEEKLY SCREENER SERVICE CONSTRUCTOR");
  }

  getisLoading() {
    return this.isLoading;
  }

  fetchLatestWeeklyScreener() {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<any>>(alsterAPI_URL + "screenerarticles/latest")
      .pipe(
        map((response) => response.data[0]),
        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "fetch latest weekly screener"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (latestWeeklyScreener) => {
          this.weeklyScreener.set(latestWeeklyScreener);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch latest weekly screener success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  fetchOverviewListAlltWeeklyScreeners(paginationParams: IQueryParams) {
    this.isLoading.set(true);
    return this.fetchItems(
      alsterAPI_URL + "screenerarticles",
      paginationParams
    ).subscribe(({ items, totalCount }) => {
      this.items.set(items);
      this.totalCount.set(totalCount || 0);
      this.isLoading.set(false);
    });
  }

  fetchWeeklyScreener(published_date, title?) {
    this.isLoading.set(true);
    let url = `${alsterAPI_URL}screenerarticles/${published_date}`;

    if (title) {
      const encodedTitle = encodeURIComponent(title);
      url += `/${encodedTitle}`;
    }

    this.http
      .get<IApiResponse<IScreenerArticle>>(url)
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "fetch weekly screener")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (latestWeeklyScreener) => {
          this.weeklyScreener.set(latestWeeklyScreener);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch weekly screener success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  public getWeeklyScreener() {
    return this.weeklyScreener as any;
  }

  public updateScreenerArticle(formData) {
    this.isLoading.set(true);
    this.http
      .put<IApiResponse<IScreenerArticle>>(
        alsterAPI_URL + "screenerarticles/edit/" + formData._id,
        formData
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "update screener article"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (screenerArticleData) => {
          this.weeklyScreener.set(screenerArticleData);
          this.adminRequestService.setLastRequestMessage("19", true);
          this.adminRequestService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  createScreenerArticle(formData) {
    this.isLoading.set(true);
    this.http
      .post<IApiResponse<IScreenerArticle>>(
        alsterAPI_URL + "screenerarticles/create",
        formData
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "create screener article"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (screenerArticleData) => {
          this.weeklyScreener.set(screenerArticleData);
          this.adminRequestService.setLastRequestMessage("20", true);
          this.adminRequestService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }
}
