import { HttpClient } from "@angular/common/http";
import {
  effect,
  inject,
  Injectable,
  signal,
  WritableSignal,
} from "@angular/core";

import { leewayAPI_URL } from "~api/api-urls";
import { CompanylistService } from "~features/company/companylist.service";
import { FrontendConfigService } from "~features/frontend-config/frontendConfig.service";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";

@Injectable({
  providedIn: "root",
})
export class AutoSliderLandingPageService {
  customSlideData: WritableSignal<any> = signal({
    title: "#EMPTY",
    description: "",
    subtitle: "",
    slider_button: "",
    imageURL: "",
    rating: "",
  });
  initiationCompany: WritableSignal<any> = signal({
    general_information: {
      description: "",
    },
  });
  randomCompany: WritableSignal<any> = signal({
    general_information: {
      description: "",
    },
  });
  randomCompanyInfo: WritableSignal<any> = signal({});
  initiationCompanyInfo: WritableSignal<any> = signal({});
  frontendConfigService = inject(FrontendConfigService);
  frontEndConfig = this.frontendConfigService.getFrontendConfig();
  private companyListService = inject(CompanylistService);
  private readonly customerId = "5eed24ff4fe2bd337ccab5d3";
  private readonly apiToken = "cqt1bii3tq5fuzo0c5id";
  private http = inject(HttpClient);

  constructor() {
    this.fetchCompanyNumbers("RH:ALS", "customSlide");

    effect(() => {
      const randomTicker = this.randomCompanyInfo()?.companyTicker;
      if (randomTicker) {
        this.fetchCompanyNumbers(randomTicker, "random");
      }
    }, enableSignalWritesInEffectContext);

    effect(() => {
      const initiationTicker = this.initiationCompanyInfo()?.companyTicker;
      if (initiationTicker) {
        this.fetchCompanyNumbers(initiationTicker, "initiation");
      }
    }, enableSignalWritesInEffectContext);

    effect(() => {
      const companyList = this.companyListService.getCompanyList();
      if (companyList() && companyList().length > 0 && this.frontEndConfig()) {
        const availableCompanies = companyList().filter(
          (company) =>
            !this.frontEndConfig().hiddenFromSliderCompanyTickers.includes(
              company.companyTicker
            )
        );
        const random = Math.floor(Math.random() * availableCompanies.length);

        // console.log("random", random);
        this.randomCompanyInfo.set(availableCompanies[random]);

        const initiationCompany = companyList().find(
          (company) => company.isFeatured === "Initiation"
        );
        this.initiationCompanyInfo.set(initiationCompany || {});
      }
    }, enableSignalWritesInEffectContext);
  }

  getRandomCompany() {
    return this.randomCompany;
  }
  getRandomCompanyInfo() {
    return this.randomCompanyInfo;
  }
  getCustomSlideData() {
    return this.customSlideData;
  }
  getInitiationCompany() {
    return this.initiationCompany;
  }
  getInitiationCompanyInfo() {
    return this.initiationCompanyInfo;
  }

  fetchCompanyNumbers(companyTicker, targetType: string) {
    const url =
      leewayAPI_URL +
      "addin/companydata/getcombinedexceldata?apitoken=" +
      this.apiToken +
      "&companyId=" +
      companyTicker +
      "&customerId=" +
      this.customerId +
      "&published=true";

    this.http.get(url).subscribe((res: any) => {
      if (targetType === "initiation") {
        this.initiationCompany.set(res);
      }
      if (targetType === "customSlide") {
        this.customSlideData.set({
          title: res.general_information.slider_title,
          description: res.general_information.slider_description,
          subtitle: res.general_information.slider_subtitle,
          slider_button: res.general_information.slider_button,
          slider_button_link: res.general_information.slider_button_link,
          imageURL: res.general_information.slider_image,
        });
      }
      if (targetType === "random") {
        this.randomCompany.set(res);
      }
    });
  }
}
