<div
  class="group fixed right-0 bottom-[10%] text-gray-700 z-50 border border-gray-200 shadow-xl text-md p-4 rounded-l-lg transition-all duration-75 hover:w-96 w-12 bg-blue-alster overflow-hidden max-h-24"
>
  <div class="">
    <div class="flex space-x-2 w-full">
      <div class="w-full grid grid-cols-3 text-xxs group-hover:text-xs">
        <div
          class="group-hover:hidden col-span-3 font-semibold uppercase tracking-widest"
        >
          <svg
            class="w-4 h-4 text-blue-alster"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376l0 103.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z"
              fill="#0F6BB2"
            />
          </svg>
        </div>
        <div class="hidden group-hover:block col-span-3 overflow-hidden">
          <a href="mailto:'info@mwb-research.com'"
            >Mail: <span class="underline">info&#64;mwb-research.com</span></a
          >
        </div>

        <div class="hidden group-hover:block col-span-3 overflow-hidden">
          Fon: +49 40 309 293-52
        </div>
      </div>
    </div>
  </div>
</div>
